import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe, DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments-evaluateur/environment';
import { AapService } from '@services-evaluateur/aap.service';
import { ComiteService } from '@services-evaluateur/comite.service';
import { EvaluateurService } from '@services-evaluateur/evaluateur.service';
import { FicheCommunicationService } from '@services-evaluateur/fiche-communication.http.service';
import { ProjetService } from '@services-evaluateur/projet.service';
import { RoutingOriginService } from '@services-evaluateur/routing-origin.service';
import { StructureService } from '@services-evaluateur/structure.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { ZoneService } from '@services-evaluateur/zone.service';
import { AffectationIntervenantLectureSeuleModalComponent } from '@shared-evaluateur/components/modals/affectation-intervenant-lecture-seule-modal/affectation-intervenant-lecture-seule-modal.component';
import { AffectationIntervenantModalComponent } from '@shared-evaluateur/components/modals/affectation-intervenant-modal/affectation-intervenant-modal.component';
import { ConfirmModalComponent } from '@shared-evaluateur/components/modals/confirm-modal/confirm-modal.component';
import { DateSelectModalComponent } from '@shared-evaluateur/components/modals/date-select-modal/date-select-modal.component';
import { DirectionRegionSelectModalComponent } from '@shared-evaluateur/components/modals/direction-regionale-select-modal/direction-regionale-select-modal.component';
import { UserWorkflow } from '@shared-evaluateur/utils/constants/workflow';
import { WFK1EXTERNE, WFK2EXTERNE } from '@shared-evaluateur/utils/constants/workflows-externe';
import { ProjetUtils } from '@shared-evaluateur/utils/projet-utils';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import {
  Aap,
  AutoAffectationModalComponent,
  CentreMesure,
  Comite,
  DateEcheanceRequest,
  DemandeQlik,
  EnumAapStatut,
  EnumDateType,
  EnumFeatureFlipping,
  EnumInstructionStatus,
  EnumPermissionUtilisateur,
  EnumPhaseComite,
  EnumProjetEtape,
  EnumProjetStatut,
  EnumProjetStatutType,
  EnumRoleContact,
  EnumTacheARealiser,
  enumValues,
  Evaluateur,
  ExportCsvFunction,
  FeatureFlagService,
  FilterDataInterface,
  getEtapeDataList,
  getStatutDataList,
  IntervenantRoles,
  NotationExportInput,
  PermissionUtils,
  Projet,
  ProjetLight,
  ProjetTableElement,
  SearchEvaluateurProjetModel,
  SearchObject,
  ShowToastrService,
  Utilisateur,
} from '@shared-ui';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AssignProjetComiteModalComponent } from './component/assign-projet-comite-modal/assign-projet-comite-modal.component';
import { DateEnvoiModalComponent } from './component/date-envoi-modal/date-envoi-modal.component';
import { DecisionComiteModalComponent } from './component/decision-comite-modal/decision-comite-modal.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { processUserWorkflowOnAap } from '@features-evaluateur/projet-list/workflow-access-processor';

@Component({
  selector: 'pxl-projet-list',
  templateUrl: './projet.list.component.html',
  styleUrls: ['./projet.list.component.scss'],
})
export class ProjetListComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);

  readonly RESPONSABLE_PROJET = (EnumRoleContact as any)[EnumRoleContact.RESPONSABLE_PROJET.toString()];
  readonly REPRESENTANT_LEGAL = (EnumRoleContact as any)[EnumRoleContact.REPRESENTANT_LEGAL.toString()];

  readonly TYPE_EN_COURS = EnumProjetStatutType.EN_COURS;
  readonly TYPE_VALIDE = EnumProjetStatutType.VALIDE;
  readonly TYPE_REJETE = EnumProjetStatutType.REJETE;
  readonly TYPE_TERMINE = EnumProjetStatutType.TERMINE;

  readonly A_FAIRE = (EnumInstructionStatus as any)[EnumInstructionStatus.A_FAIRE.toString()];
  readonly FAITE = (EnumInstructionStatus as any)[EnumInstructionStatus.FAITE.toString()];

  expertJuryNational = 'EXPERT_JURY_NATIONAL';

  // TODO Remove feature fliping archive V1
  displayedColumnsOld: string[] = [
    'select',
    'acronyme',
    'structureCf',
    'budget',
    'libelleThematique',
    'relevePreDepot',
    'releveDepot',
    'etape',
    'statut',
    'tag',
    'dateEvaluation',
    'dateAudition',
  ];
  // TODO Remove feature fliping archive V1

  displayedColumns: string[] = [
    'select',
    'identifiantPXL',
    'acronyme',
    'structureCf',
    'budget',
    'aideDemande',
    'libelleThematique',
    'relevePreDepot',
    'releveDepot',
    'etape',
    'statut',
    'tag',
    'dateEvaluation',
    'dateAudition',
  ];

  projets: ProjetLight[] = [];
  projetTableData: ProjetTableElement[] = [];
  project: Projet = new Projet();
  projetsByDateReleve: ProjetLight[] = [];
  aap: Aap = new Aap();
  drdl!: CentreMesure[];
  comites: Comite[] = [];
  aapId: string;
  count: any = 0;
  selection = new SelectionModel<ProjetTableElement>(true, []);
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  activeToast!: ActiveToast<any>;
  typeWkf!: UserWorkflow;
  evaluateur!: Evaluateur;
  user!: Utilisateur;
  canUserAutoAffect = false;

  isUserDar = false;
  isUserGroup = false;
  affecter = 'Affecter Evaluateur';
  desaffecter = 'Désaffecter';
  ilabTitle =
    'La désaffectation concerne uniquement les projets sélectionnés et le Jury régional (Expert indépendant affecté via leur email) et le Jury national (Expert indépendant et (Vice) Président affectés via leur email). Dans le cas de la désaffectation, les intervenants ne sont pas notifiés.';
  noIlabtitle =
    'La désaffectation concerne uniquement les projets sélectionnés et les profils Expert ministère/ Expert indépendant/ Accès en lecture seule pour Etat et autre évaluateur. Dans le cas de la désaffectation, les intervenants ne sont pas notifiés.';
  title = '';
  president = 'VICE_PRESIDENT_JURY_NATIONAL';

  // SEARCH
  showSearchBar = false;
  thematiqueDataList!: FilterDataInterface[];
  releveDataList!: FilterDataInterface[];
  etapeDataList!: FilterDataInterface[];
  statutDataList!: FilterDataInterface[];
  tacheDataList!: FilterDataInterface[];
  drdlDataList!: FilterDataInterface[];
  comiteDataList!: FilterDataInterface[];
  phaseDecisionDataList!: FilterDataInterface[];

  toggleLabels: string[];
  defaultToggleLabel = ToggleValues.TOUS_PROJETS;
  isMesProjetsToggleSelected = false;
  isAapIlab = false;
  savedSort!: Sort;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private aapService: AapService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private showToastrService: ShowToastrService,
    public datePipe: DatePipe,
    private projetService: ProjetService,
    private structureService: StructureService,
    private userService: UtilisateurService,
    public sharedFunction: SharedFunction,
    public exportCsvFunction: ExportCsvFunction,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private evaluateurService: EvaluateurService,
    public featureFlagService: FeatureFlagService,
    private drdlHttpService: ZoneService,
    private routingOriginService: RoutingOriginService,
    private ficheCommunicationService: FicheCommunicationService,
    public comiteService: ComiteService
  ) {
    this.aapId = this.route.snapshot.params.id;
    this.toggleLabels = enumValues(ToggleValues);
  }

  parsePhoneNumber(phone: string): string {
    phone = phone.replace(/\d{2}(?=.)/g, '$& ');
    return phone;
  }

  ngOnInit(): void {
    this.getSavedSort();
    this.userService
      .getUtilisateurObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(user => {
        this.user = user;
        this.isUserDar = this.sharedFunction.isUserDar(this.user);
        this.isUserGroup = this.sharedFunction.isUserGroup(this.user);
      });

    this.renderer.addClass(this.document.body, 'nav-collapsed');
    const fork = forkJoin({
      aap: this.aapService.getAapById(this.aapId),
      drdl: this.drdlHttpService.getDirectionRegionaleCentreMesure(),
    });

    fork.pipe(catchError(error => of(error)));

    fork.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: result => {
        this.aap = result.aap.body;
        this.title = this.aap.ilab ? this.ilabTitle : this.noIlabtitle;
        this.drdl = result.drdl.body;
        this.isAapIlab = this.aap.ilab;

        const wkf = this.aap.typeWKF === 'WKF1' ? WFK1EXTERNE : WFK2EXTERNE;
        this.typeWkf = processUserWorkflowOnAap(wkf, this.aap, this.user);

        this.removeUnusedWfkEtapes();

        this.canUserAutoAffect = this.sharedFunction.isAutoAffectableOnAap(this.aap, this.user);

        this.loadSearchData();
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });

    this.loadProjets();
  }

  removeUnusedWfkEtapes(): void {
    if (!this.aap.instructionPresent) {
      this.typeWkf = this.typeWkf.filter(wkf => wkf.etape !== EnumProjetEtape.INSTRUCTION);
    }
  }

  loadProjets(): void {
    this.evaluateurService
      .getEvaluateurObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => {
        this.evaluateur = response?.body;
        if (!this.sharedFunction.isEvalPoleLabellisateur(this.evaluateur, this.project) && !this.displayedColumnsOld.includes('archive')) {
          this.displayedColumnsOld.push('archive');
        }
        this.updateToggleSelection(ToggleValues.MES_PROJETS);
        this.loadProjectsForEvaluateur();
      });
  }

  private getFilterSearch(): SearchObject {
    const searchObjectLocalStorage = localStorage.getItem('filtersSearchObject');
    const searchObject = searchObjectLocalStorage ? JSON.parse(searchObjectLocalStorage) : new SearchObject();
    searchObject.idAAPs = [this.aapId];
    return searchObject;
  }

  loadProjectsForEvaluateur(): void {
    this.selection?.clear();

    if (this.sharedFunction.isEvaluateurInstructeurExterne(this.evaluateur)) {
      const searchCriteria = new SearchObject();
      searchCriteria.idAAPs = [this.aapId];
      searchCriteria.affectedOnly = this.isMesProjetsToggleSelected;
      this.loadProjectsForInstructeurExterne(searchCriteria);
    } else {
      const searchCriteria: SearchEvaluateurProjetModel = {
        aapId: this.aapId,
        isAffected: this.isMesProjetsToggleSelected,
        aapOrigineId: null,
      };
      this.projetService
        .searchLightProjetsForEvaluateur(searchCriteria)
        .pipe(take(1))
        .subscribe({
          next: (projets: ProjetLight[]) => {
            this.feedProjectDatasource(projets);
            if (this.isMesProjetsToggleSelected && projets.length === 0) {
              this.updateToggleSelection(ToggleValues.TOUS_PROJETS);
              this.loadProjectsForEvaluateur();
            }
          },
          error: (err: HttpErrorResponse) => this.showToastrService.checkCodeError(err?.error),
        });
    }
  }
  loadProjectsForInstructeurExterne(searchCriteria: SearchObject) {
    return this.projetService
      .searchLightProjetsForInstructeurExterne(searchCriteria)
      .pipe(take(1))
      .subscribe({
        next: (projets: ProjetLight[]) => {
          this.feedProjectDatasource(projets);
          if (this.isMesProjetsToggleSelected && projets.length === 0) {
            this.updateToggleSelection(ToggleValues.TOUS_PROJETS);
            this.loadProjectsForEvaluateur();
          }
        },
        error: (err: HttpErrorResponse) => this.showToastrService.checkCodeError(err?.error),
      });
  }

  private feedProjectDatasource(projets: ProjetLight[]): void {
    if (projets) {
      this.projets = projets;
      this.projetTableData = ProjetUtils.convertProjetLightToTableElement(projets);
    }
  }

  loadSearchData(): void {
    this.thematiqueDataList = this.aap.thematiques.map(thematique => {
      return {
        id: thematique.codeThematique,
        value: thematique.libelleThematique,
        isSelected: false,
      };
    });
    this.releveDataList = this.aap.dateAutres
      .filter(date => date.type === EnumDateType.RELEVE)
      .sort((date1, date2) => {
        return new Date(date1?.valeur).getTime() - new Date(date2?.valeur).getTime();
      })
      .map(date => {
        return {
          id: date.valeur.toString(),
          value: this.datePipe.transform(date.valeur.toString(), 'dd/MM/yyyy'),
          isSelected: false,
        };
      });

    this.etapeDataList = getEtapeDataList();

    this.statutDataList = getStatutDataList();

    this.drdlDataList = this.drdl
      .map(drdl => {
        return {
          id: drdl.codeCentreMesure,
          value: drdl.libCentreMesure,
          isSelected: false,
        };
      })
      .sort((a, b) => (a.value > b.value ? 1 : -1));

    this.loadTacheDataList();
    this.loadComiteDataList();
    this.loadPhaseDecisionDataList();

    this.showSearchBar = true;
  }

  loadPhaseDecisionDataList(): void {
    this.phaseDecisionDataList = [];
    this.phaseDecisionDataList.push(
      ...[
        { id: EnumPhaseComite.EVALUATION, value: 'Présélection', isSelected: false },
        { id: EnumPhaseComite.AUDITION, value: 'Audition', isSelected: false },
        { id: EnumPhaseComite.SELECTION, value: 'Sélection', isSelected: false },
      ]
    );
  }

  loadComiteDataList(): void {
    this.comiteDataList = [];
    this.comites?.forEach(comite => {
      this.comiteDataList.push({
        id: comite.id,
        value: `${comite.nom} (${this.datePipe.transform(comite.dateTenue, 'dd/MM/yyyy')})`,
        isSelected: false,
      });
    });
  }

  loadTacheDataList(): void {
    this.tacheDataList = [];
    if (!this.featureFlagService.featureOff(EnumFeatureFlipping.VERROUILLAGE)) {
      this.addTacheFilterFields(EnumTacheARealiser.VERROUILLAGE_A_FAIRE);
      this.addTacheFilterFields(EnumTacheARealiser.VERROUILLAGE_FAIT);
    }
    if (this.aap?.eligibilitePresent && !this.featureFlagService.featureOff(EnumFeatureFlipping.ELIGIBILITE)) {
      this.addTacheFilterFields(EnumTacheARealiser.ELIGIBILITE_A_FAIRE);
      this.addTacheFilterFields(EnumTacheARealiser.ELIGIBILITE_FAIT);
    }
    if (!this.featureFlagService.featureOff(EnumFeatureFlipping.OPPORTUNITE)) {
      this.addTacheFilterFields(EnumTacheARealiser.OPPORTUNITE_A_FAIRE);
      this.addTacheFilterFields(EnumTacheARealiser.OPPORTUNITE_FAITE);
    }
    if (!this.featureFlagService.featureOff(EnumFeatureFlipping.AUDITION)) {
      this.addTacheFilterFields(EnumTacheARealiser.AUDITION_A_FAIRE);
      this.addTacheFilterFields(EnumTacheARealiser.AUDITION_FAITE);
    }
    if (!this.featureFlagService.featureOff(EnumFeatureFlipping.INSTRUCTION_APPROFONDIE_EVALUATEUR_ILAB)) {
      this.addTacheFilterFields(EnumTacheARealiser.INSTRUCTION_A_FAIRE);
      this.addTacheFilterFields(EnumTacheARealiser.INSTRUCTION_FAITE);
    }
    if (!this.featureFlagService.featureOff(EnumFeatureFlipping.INSTRUCTION_APPROFONDIE_EVALUATEUR_ILAB)) {
      this.addTacheFilterFields(EnumTacheARealiser.SYTHESE_COMPLETUDE_SECOND_NIVEAU_A_FAIRE);
      this.addTacheFilterFields(EnumTacheARealiser.SYTHESE_COMPLETUDE_SECOND_NIVEAU_FAITE);
      this.addTacheFilterFields(EnumTacheARealiser.CONTROLE_SECOND_NIVEAU_A_FAIRE);
      this.addTacheFilterFields(EnumTacheARealiser.CONTROLE_SECOND_NIVEAU_FAITE);
    }
    if (this.aap?.contractualisationPresent) {
      this.addTacheFilterFields(EnumTacheARealiser.A_EDITER);
      this.addTacheFilterFields(EnumTacheARealiser.EDITE_A_RELIRE_DJPI);
      this.addTacheFilterFields(EnumTacheARealiser.EDITE_RELU_DJPI);
    }
  }

  addTacheFilterFields(tache: EnumTacheARealiser) {
    this.tacheDataList.push({
      id: tache,
      value: EnumTacheARealiser.toString(tache),
      isSelected: false,
    });
  }

  searchEventCalled(searchObject: SearchObject): void {
    searchObject.idAAPs = [this.aap.id];
    searchObject.affectedOnly = this.isMesProjetsToggleSelected;
    this.loadProjectsForInstructeurExterne(searchObject);
  }

  /**
   * Redirige vers l'espace candidats
   */
  redirectToCandidats(): void {
    window.location.href = environment.espaceCandidat + '/projets';
  }

  disableMenu(etape: EnumProjetEtape, optionType: EnumProjetStatutType): boolean {
    if (!this.selection.selected.length) {
      return true;
    }

    if (this.checkDepotPreDepot()) {
      return true;
    }
    if (this.checkEtapesetStatut()) {
      return true;
    }
    if (this.isProjetContractualisationTermine()) {
      return etape !== EnumProjetEtape.CONTRACTUALISATION || optionType !== this.TYPE_EN_COURS;
    }
    if (etape === EnumProjetEtape.CONTRACTUALISATION) {
      return !this.checkInstructionEtapeValidated();
    }
    return false;
  }

  private getSelectedProjets(selectedTableElements: ProjetTableElement[]) {
    const projetIds = selectedTableElements.map(selec => selec.id);
    return this.projets.filter(proj => projetIds.includes(proj.id));
  }

  private isProjetContractualisationTermine(): boolean {
    return this.getSelectedProjets(this.selection.selected).every(selectedProjet => selectedProjet.contractualisationTermine);
  }

  private checkInstructionEtapeValidated(): boolean {
    return this.getSelectedProjets(this.selection.selected).every(selectedProjet => selectedProjet.instructionValidated);
  }

  showMsg(): void {
    let showMenu = true;
    if (!this.selection.selected?.length) {
      this.showToastrService.error('Merci de sélectionner au moins un projet.');
      showMenu = false;
    }
    if (this.checkDepotPreDepot()) {
      this.showToastrService.error(
        'Parmi les projets sélectionnés certains sont en cours côté client. Merci de ne pas sélectionner les projets en cours de pré-dépôt ou dépôt.',
        ''
      );
      showMenu = false;
    }
    if (this.checkEtapesetStatut()) {
      const etapesAndStatuts = this.getSelectedEtapesAndStatuts();
      const EtapesName = Array.from(etapesAndStatuts.etapes).join(', ');
      const statusName = Array.from(etapesAndStatuts.statuts).join(', ');

      this.showToastrService.error(
        `Plusieurs étapes [${EtapesName}] et/ou plusieurs statuts [${statusName}] ont été sélectionnés pour faire une action par lot. Merci de ne sélectionner qu'une étape et qu'un statut à la fois.`,
        ''
      );
      showMenu = false;
    }
    if (!showMenu) {
      this.menuTrigger.closeMenu();
    }
  }

  checkEtapesetStatut(): boolean {
    const etapesAndStatuts = this.getSelectedEtapesAndStatuts();
    return etapesAndStatuts.etapes.size > 1 || etapesAndStatuts.statuts.size > 1;
  }

  private getSelectedEtapesAndStatuts() {
    const etapes = new Set(
      this.selection.selected.filter(prj => prj.etape != null && prj.etape != ProjetUtils.EMPTY_FIELD).map(prj => prj.etape)
    );
    const statuts = new Set(
      this.selection.selected.filter(prj => prj.statut != null && prj.statut != ProjetUtils.EMPTY_FIELD).map(prj => prj.statut)
    );
    return { etapes, statuts };
  }

  checkDepotPreDepot(): boolean {
    if (this.selection.selected.length < 1) {
      return false;
    }
    return this.getSelectedProjets(this.selection.selected).some(
      selectedProject =>
        selectedProject.statut === EnumProjetStatut.EN_COURS &&
        [EnumProjetEtape.PRE_DEPOT, EnumProjetEtape.DEPOT].includes(selectedProject.derniereEtapeNom)
    );
  }

  // Download projet CSV
  downloadExportProjets(): void {
    const projectIds = this.projets.map(projet => projet.id);
    this.projetService.downloadExportProjets({ projectIds }).subscribe({
      next: response => {
        if (response && response.body) {
          this.exportCsvFunction.downloadFile(response.body, this.exportCsvFunction.extractFileNameFromHeaders(response.headers));
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  // Download partenaire CSV
  downloadPartenaire(): void {
    this.getLoadProjetsObservable().subscribe({
      next: (projets: Projet[]) => {
        this.exportCsvFunction.downloadPartenairesCsv(projets, [this.aap]);
      },
    });
  }

  // Download PIA CSV
  downloadPIA(): void {
    this.getLoadProjetsObservable().subscribe({
      next: (projets: Projet[]) => {
        this.exportCsvFunction.downloadPiaCSV(projets, [this.aap]);
      },
    });
  }

  private getLoadProjetsObservable() {
    const searchCriteria: SearchEvaluateurProjetModel = {
      aapId: this.aapId,
      isAffected: this.isMesProjetsToggleSelected,
      aapOrigineId: null,
    };

    return this.projetService.searchProjetsForEvaluateur(searchCriteria).pipe(
      take(1),
      map((projets: Projet[]) => this.sortProjets(projets)),
      catchError((err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
        return throwError(() => err);
      })
    );
  }

  private sortProjets(projets: Projet[]) {
    return [...projets].sort((a, b) => {
      if (!a.relevePreDepot && b.relevePreDepot) {
        return 1;
      } else if (a.relevePreDepot && !b.relevePreDepot) {
        return -1;
      } else {
        return (new Date(b.relevePreDepot).getTime() as any) - (new Date(a.relevePreDepot).getTime() as any);
      }
    });
  }

  openAutoDeaffectation(): void {
    if (!this.selection.selected?.length) {
      this.showToastrService.error('Vous devez choir au moins un projet pour valider une désaffectation.');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Confirmez-vous l'auto désaffectation?",
        description: `<p>Vous avez demandé à être désaffecté des projets sélectionnés, cette action vous fera perdre vos droits sur ces projets sans affecter les éventuelles données renseignées.
                          <br />Confirmez-vous cette action ?</p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const projetIds = this.selection.selected.map(project => project.id);
        this.projetService.autoDeaffect(projetIds).subscribe({
          next: () => {
            this.toastr.success("L'opération de désaffectation a été appliquée avec succès.");
            this.selection?.clear();
            this.updateProjectsSource();
          },
          error: err => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
      }
    });
  }

  openAutoAffectation(modification: boolean): void {
    if (!this.selection.selected?.length) {
      this.showToastrService.error('Merci de sélectionner au moins un projet.');
      return;
    } else if (this.selection.selected?.length > 50) {
      this.showToastrService.error('Vous avez sélectionné plus de 50 projets');
      return;
    }

    const dialogRef = this.dialog.open(AutoAffectationModalComponent, {
      data: {
        projets: this.getSelectedProjets(this.selection.selected),
        aap: this.aap,
        user: this.user,
        withIntervenantPIOption: false,
        withReferentOption: false,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const preAffectation: Observable<any> = modification ? this.projetService.autoDeaffect(result.projetIds) : of(null);
        preAffectation
          .pipe(
            switchMap(() =>
              this.projetService.autoAffectInstructeur(result.referent, result.intervenantPI, result.projetIds, result.activites)
            )
          )
          .subscribe({
            next: () => {
              this.toastr.success("L'opération d'affectation a été appliquée avec succès.");
              this.updateToggleSelection(ToggleValues.MES_PROJETS);
              this.updateProjectsSource();
            },
            error: err => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
      }
    });
  }

  affecterDesaffecterIntervenant(title: string): void {
    if (!this.aap.notationAuditionPresent && !this.aap.notationEvaluationPresent && title === this.affecter) {
      this.showToastrService.error(
        `Il n'est pas possible d'affecter des experts du ministère ou indépendants, les notations des phases évaluation et audition ne sont pas ouvertes sur cet AAP.`
      );
      return;
    }

    if (!this.selection.selected?.length) {
      this.showToastrService.error('Merci de sélectionner au moins un projet.');
      return;
    } else if (this.selection.selected?.length > 50) {
      this.showToastrService.error('Vous avez sélectionné plus de 50 projets');
      return;
    }

    const dialogRef = this.dialog.open(AffectationIntervenantModalComponent, {
      data: {
        title: title,
        affecterEval: title === this.affecter,
        subTitle: title === this.desaffecter ? this.title : null,
        textGoButton: 'Envoyer',
        textReturnButton: 'Annuler',
        aap: this.aap,
        projectList: this.getSelectedProjets(this.selection.selected).map(projet => {
          return {
            id: projet.id,
            dateEvaluation: projet.dateEvaluation,
            dateAudition: projet.dateAudition,
            phaseEvaluation: projet.phaseEvaluation,
            phaseAudition: projet.phaseAudition,
          };
        }),
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.manageAffectDesaffectModalResult(title, result);
      }
    });
  }

  manageAffectDesaffectModalResult(title: string, result: any): void {
    const url =
      title === this.affecter
        ? this.evaluateurService.affectEvaluateurs(result.role, result.projectsIdArray, result.emails, result.notationEtapes)
        : this.evaluateurService.desaffectEvaluateurs(result.projectsIdArray, result.emails);

    url.subscribe({
      next: res => {
        const errorArray = res.body;
        if (errorArray.length) {
          this.showAffectationError(errorArray);
        } else {
          if (title === this.affecter) {
            this.showToastrService.success("L'opération d'affectation a été appliquée avec succès.");
          } else {
            this.showToastrService.success("L'opération de désaffectation a été appliquée avec succès.");
          }
        }
      },
      error: err => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
    url.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: res => {
        const errorArray = res.body;
        if (errorArray.length) {
          this.showAffectationError(errorArray);
        } else {
          if (title === this.affecter) {
            this.showToastrService.success("L'opération d'affectation a été appliquée avec succès.");
          } else {
            this.showToastrService.success("L'opération de désaffectation a été appliquée avec succès.");
          }
        }
      },
      error: err => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  affecterIntervenantLecttureSeule(): void {
    if (!this.aap.notationAuditionPresent && !this.aap.notationEvaluationPresent) {
      this.showToastrService.error(
        `Il n'est pas possible d'affecter des experts du ministère ou indépendants, les notations des phases évaluation et audition ne sont pas ouvertes sur cet AAP.`
      );
      return;
    }

    if (!this.selection.selected?.length) {
      this.showToastrService.error('Merci de sélectionner au moins un projet.');
      return;
    } else if (this.selection.selected?.length > 50) {
      this.showToastrService.error('Vous avez sélectionné plus de 50 projets');
      return;
    }

    const dialogRef = this.dialog.open(AffectationIntervenantLectureSeuleModalComponent, {
      data: {
        title: 'Affecter en lecture seule',
        textGoButton: 'Envoyer',
        textReturnButton: 'Annuler',
      },
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        if (result.role && result.emails) {
          const idProjects = this.selection.selected.map(project => project.id);
          this.evaluateurService.affectEvaluateursLectureSeule(result.role, idProjects, result.emails).subscribe({
            next: res => {
              const errorArray = res.body;
              if (errorArray.length) {
                this.showAffectationError(errorArray);
              } else {
                this.showToastrService.success("L'opération d'affectation a été appliquée avec succès.");
              }
            },
            error: err => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
        }
      });
  }

  downloadDossiersComplets(): void {
    if (!this.selection.selected?.length) {
      this.showToastrService.error('Merci de sélectionner au moins un projet.');
    } else if (this.selection.selected?.length > 15) {
      this.showToastrService.error('Vous ne pouvez pas télécharger tous les documents de plus de 15 projets en même temps');
    } else {
      const projetsIds = this.selection.selected.map(proj => proj.id);
      this.projetService.downloadDossiersComplets(projetsIds).subscribe({
        next: () => {
          this.toastr.success(
            "La demande de génération de l'archive est en cours, vous devriez recevoir un mail d'ici quelques minutes." +
              '</br></br>' +
              'Si aucun des projets sélectionnés ne comporte des documents, aucun mail ne sera envoyé.',
            '',
            { enableHtml: true }
          );
        },
        error: () => {
          this.showToastrService.checkCodeError({ code: 'erreur.telechargement.document' });
        },
      });
    }
  }

  downloadFicheCommunication(): void {
    if (!this.selection.selected?.length) {
      this.showToastrService.error('Merci de sélectionner au moins un projet.');
    } else if (this.selection.selected?.length > 15) {
      this.showToastrService.error('Vous ne pouvez pas télécharger les fiches de communication de plus de 15 projets en même temps');
    } else {
      const demandeQlik = new DemandeQlik();
      demandeQlik.projetIds = this.selection.selected.map(proj => proj.id);
      this.ficheCommunicationService
        .createDemandeQlik(demandeQlik)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.toastr.success('Votre demande a bien été prise en compte vous allez recevoir un mail sous 24h', '', { enableHtml: true });
          },
          error: (errorResponse: HttpErrorResponse) => {
            this.toastr.error(errorResponse?.error?.message, '', { enableHtml: true });
          },
        });
    }
  }

  showAffectationError(errorArray: { email: string; projects: any[]; message: string }[]): void {
    const incorrectList: string[] = [];
    errorArray.forEach(error => {
      error.projects?.forEach(project => {
        incorrectList.push(
          `L'email ${error.email} est affecté en tant que ${IntervenantRoles.toString(project.utilisateurRole)} sur le projet ${
            project.acronyme ? project.acronyme : project.identifiantPXL
          }`
        );
      });

      if (error.message) {
        incorrectList.push(error.message);
      }
    });

    this.showToastrService.error(incorrectList?.join(', '), "Erreurs d'affectation.");
  }

  addEvaluationDate(): void {
    this.addDate("Date d'évaluation", "Présélection pour l'audition", EnumProjetEtape.EVALUATION);
  }

  addAuditionDate(): void {
    this.addDate("Date d'audition", "Présélection pour l'instruction", EnumProjetEtape.AUDITION);
  }

  canUpdateWorkflow(): boolean {
    return PermissionUtils.hasAnyPermissionOnAap(
      this.user,
      [
        EnumPermissionUtilisateur.WKF_PREDEPOT_EVALUATION,
        EnumPermissionUtilisateur.WKF_PREDEPOT_AUDITION,
        EnumPermissionUtilisateur.WKF_DEPOT_ENCOURS,
        EnumPermissionUtilisateur.WKF_DEPOT_ANALYSE,
        EnumPermissionUtilisateur.WKF_CONTRACTUALISATION,
        EnumPermissionUtilisateur.WKF_INSTRUCTION,
        EnumPermissionUtilisateur.WKF_DEPOT_AUDITION,
        EnumPermissionUtilisateur.WKF_DEPOT_EVALUATION,
      ],
      this.aap
    );
  }

  goToProjetParcours(): void {
    if (this.aap.statut !== EnumAapStatut.FERME) {
      this.router.navigate(['aaps/' + this.aap.id + '/projet-parcours']);
    } else {
      this.showToastrService.error(
        "L'AAP est actuellement fermé, la création d'un nouveau projet n'est pas possible. Merci d'ouvrir l'AAP pour pouvoir faire cette action."
      );
    }
  }

  downloadExtraitDPM(): void {
    if (this.selection.selected == null || this.selection.selected.length === 0) {
      this.showToastrService.error('Vous devez sélectionner au moins un projet.');
      return;
    }

    const idProjets = this.selection.selected.map(project => project.id);
    this.projetService.downloadExtraitDPM(idProjets).subscribe({
      next: response => {
        if (response && response.body) {
          this.exportCsvFunction.downloadFile(
            response.body,
            'Extrait_DPM_' + this.aap.code + '_' + this.datePipe.transform(new Date(), 'ddMMyyyy')
          );
        }
      },
      error: (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.showToastrService.checkCodeError({ code: '1333' });
        } else {
          this.showToastrService.checkCodeError(err?.error);
        }
      },
    });
  }

  editDirectionReg(): void {
    if (!this.selection.selected?.length) {
      this.showToastrService.error('Merci de sélectionner un projet.');
      return;
    }

    if (this.selection.selected?.length > 1) {
      this.showToastrService.error(
        "La modification de la Direction Régionale n'est pas une action par lot, merci de ne sélectionner qu'un seul projet."
      );
      return;
    }

    const selectedProjet = this.getSelectedProjets(this.selection.selected)[0];

    const sourcesObservables: Record<string, Observable<any>> = {
      projet: this.projetService.getProjetById(selectedProjet.id),
    };

    if (selectedProjet.structureCf) {
      sourcesObservables.centreMesures = this.structureService.getStructureDirectionRegionale([selectedProjet.structureCf.id]);
    }

    forkJoin(sourcesObservables)
      .pipe(take(1))
      .subscribe({
        next: response => {
          if (response.projet.body != null) {
            const structureCfCentreMeasure = response.centreMesures != null ? response.centreMesures.body[0] : null;
            this.openModalSelectDrdl(response.projet.body.centreMesure, structureCfCentreMeasure);
          }
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  openModalSelectDrdl(centreMesureProjet: CentreMesure, drdlCalculated?: CentreMesure): void {
    const dialogRef = this.dialog.open(DirectionRegionSelectModalComponent, {
      data: {
        drCalculated: drdlCalculated,
        drdlList: this.drdl,
        drdlProject: centreMesureProjet,
        title: 'Modification de la Direction régionale Bpifrance',
        textGoButton: 'Enregistrer',
        textReturnButton: 'Annuler',
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result: CentreMesure) => {
      if (result) {
        this.projetService.putDirectionRegionale(result, [this.selection.selected[0].id]).subscribe({
          next: () => {
            centreMesureProjet = result;
            this.showToastrService.success('Modification de la Direction régionale avec succès');
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
      }
    });
  }

  addDate(title: string, subtitle: string, type: EnumProjetEtape): void {
    if (!this.selection.selected?.length) {
      this.showToastrService.error('Merci de sélectionner au moins un projet.');
      return;
    }
    const dialogRef = this.dialog.open(DateSelectModalComponent, {
      data: {
        title,
        subtitle,
        label: `Date`,
        placeholder: `JJ/MM/AAAA`,
        textGoButton: 'Valider',
        textReturnButton: 'Annuler',
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result: { date: Date }) => {
      if (!result.date) {
        return;
      }
      const dateRequest: DateEcheanceRequest = {
        idProjets: this.selection.selected.map(project => project.id),
        dateEvaluation: type === EnumProjetEtape.EVALUATION ? result.date : null,
        dateAudition: type !== EnumProjetEtape.EVALUATION ? result.date : null,
      };
      this.projetService.putDateEcheance(dateRequest).subscribe({
        next: () => {
          this.mergeDateToExistingProjets(dateRequest.idProjets, result.date, type);
          this.projetTableData = ProjetUtils.convertProjetLightToTableElement(this.projets);
          this.toastr.success(`Date d'${EnumProjetEtape.toString(type).toLowerCase()} enregistrée avec succès.`);
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    });
  }

  private mergeDateToExistingProjets(editedProjetsIds: string[], date: Date, type: EnumProjetEtape) {
    this.projets.forEach(proj => {
      if (editedProjetsIds.includes(proj.id)) {
        if (type === EnumProjetEtape.EVALUATION) {
          proj.dateEvaluation = date;
        } else {
          proj.dateAudition = date;
        }
      }
    });
  }

  downloadExportAffectationsProjets() {
    const projectIds = this.projets.map(projet => projet.id);
    this.projetService.downloadExportAffectationsProjets({ projectIds }).subscribe({
      next: response => {
        if (response && response.body) {
          this.exportCsvFunction.downloadFile(response.body, this.exportCsvFunction.extractFileNameFromHeaders(response.headers));
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  /*
   * Download des enquetes de l'aap
   * */
  downloadEnquetes(): void {
    const projectIds = this.projets.map(projet => projet.id);
    this.projetService.downloadEnquetes(projectIds).subscribe({
      next: response => {
        if (response && response.body) {
          this.exportCsvFunction.downloadFile(response.body, 'Données enquête_' + this.aap.nom);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  /*
   * Download des notations de projets selectionnés
   * */
  downloadNotationsProjets(): void {
    const notationExportInput: NotationExportInput = new NotationExportInput();
    notationExportInput.projectIds = this.projets.map(projet => projet.id);

    this.projetService.downloadNotationsProjets(notationExportInput).subscribe({
      next: response => {
        if (response && response.body) {
          this.exportCsvFunction.downloadFile(
            response.body,
            'Notations projets-' + this.aap.nom + '-' + new Date().toISOString().split('T')[0]
          );
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  updateProjetEtapes(etape: EnumProjetEtape, statut: EnumProjetStatut): void {
    if (this.isSelectedProjetNotInInstructionFaite(etape, statut)) {
      this.showToastrService.error(
        'Parmi les projets sélectionnés un ou plusieurs ont toujours leur instruction en cours, merci de passer toutes les instructions à "Faite"'
      );
      return;
    }
    const selectedProjetIds = this.selection.selected.map(selectedProjet => selectedProjet.id);
    this.projetService.updateProjetEtape(selectedProjetIds, this.project, etape, statut).subscribe({
      next: (rep: any) => {
        if (rep) {
          this.updateProjectsSource();
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
      complete: () => {
        this.onChangeToggle(this.isMesProjetsToggleSelected ? ToggleValues.MES_PROJETS : ToggleValues.TOUS_PROJETS);
      },
    });
  }

  /**
   * si l'un des projets selectionné n'a pas instruction  "Faite" alors au clic sur Valider de la phase instruction
   * renvoyer true
   */
  isSelectedProjetNotInInstructionFaite(etape: EnumProjetEtape, statut: EnumProjetStatut): boolean {
    const selectedWithInstructionFAITE = this.getSelectedProjets(this.selection.selected).find(
      projet => projet.instruction?.statut === this.FAITE
    );
    return etape === EnumProjetEtape.INSTRUCTION && statut === EnumProjetStatut.VALIDE && !selectedWithInstructionFAITE;
  }

  canUpdateThisStep(etape: EnumProjetEtape): boolean {
    if (etape === EnumProjetEtape.PRE_DEPOT) {
      return PermissionUtils.hasPermissionOnAap(this.user, EnumPermissionUtilisateur.WKF_PREDEPOT, this.aap);
    } else {
      return PermissionUtils.hasPermissionOnAap(this.user, EnumPermissionUtilisateur.WKF_DEPOT, this.aap);
    }
  }

  updateToggleSelection(toggleValue: ToggleValues): void {
    this.isMesProjetsToggleSelected = toggleValue === ToggleValues.MES_PROJETS;
    this.defaultToggleLabel = toggleValue;
  }

  onChangeToggle($event: string): void {
    this.updateToggleSelection($event as ToggleValues);
    this.updateProjectsSource();
    this.selection?.clear();
  }

  private updateProjectsSource(): void {
    const searchObject = this.getFilterSearch();
    searchObject.affectedOnly = this.isMesProjetsToggleSelected;
    this.loadProjectsForEvaluateur();
  }

  affecterJuryNational(): void {
    if (!this.selection.selected?.length) {
      this.toastr.error('Merci de sélectionner au moins un AAP.');
      return;
    }

    const dialogRef = this.dialog.open(AffectationIntervenantModalComponent, {
      data: {
        title: 'Affecter expert(s) du jury national',
        textGoButton: 'Envoyer',
        textReturnButton: 'Annuler',
        projectList: this.selection.selected,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.projectsIdArray) {
        const url = this.evaluateurService.affectJuryNational(this.expertJuryNational, result.projectsIdArray, result.emails);
        url.subscribe({
          next: res => {
            const errorArray = res.body;
            if (errorArray.length) {
              this.showAffectationError(errorArray);
            } else {
              this.toastr.success("L'opération d'affectation a été appliquée avec succès.");
            }
          },
          error: (err: HttpErrorResponse) => {
            this.toastr.error(err.error.code);
          },
        });
      }
    });
  }

  affecterPresident(): void {
    if (!this.selection.selected?.length) {
      this.toastr.error('Merci de sélectionner au moins un AAP.');
      return;
    }

    const dialogRef = this.dialog.open(AffectationIntervenantModalComponent, {
      data: {
        title: 'Affecter (vice) président(s) du jury national',
        subTitle:
          "L'affectation du vice président lui permet d'accéder en lecture seule aux notations et aux informations du jury national",
        textGoButton: 'Envoyer',
        textReturnButton: 'Annuler',
        projectList: this.selection.selected,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.projectsIdArray) {
        const url = this.evaluateurService.affectEvaluateursLectureSeule(this.president, result.projectsIdArray, result.emails);
        url.subscribe({
          next: res => {
            const errorArray = res.body;
            if (errorArray.length) {
              this.showAffectationError(errorArray);
            } else {
              this.toastr.success("L'opération d'affectation a été appliquée avec succès.");
            }
          },
          error: (err: HttpErrorResponse) => {
            this.toastr.error(err.error.code);
          },
        });
      }
    });
  }

  ngOnDestroy(): void {
    // Si un toastr d'erreur est déjà ouvert, on le ferme
    if (this.activeToast) {
      this.toastr.clear(this.activeToast.toastId);
    }
  }

  private getSavedSort() {
    const sort = localStorage.getItem('aap-project-list-sorting');
    if (sort) {
      this.savedSort = JSON.parse(sort);
      localStorage.removeItem('aap-project-list-sorting');
    }
  }

  onSortChange(event: Sort) {
    this.savedSort = event;
  }

  /**
   * Redirige vers la page ayant les informations du projet
   */
  goToProjetInfo(projet: ProjetLight): void {
    this.routingOriginService.setIsFromTransverse(false);

    if (
      this.sharedFunction.isEvalPoleLabellisateur(this.evaluateur, projet as unknown as Projet) ||
      this.sharedFunction.canUserEvaluateProject(this.user, projet as unknown as Projet) ||
      this.sharedFunction.canUserAuditProject(this.user, projet as unknown as Projet)
    ) {
      this.router.navigate(['/projets/' + projet.id]);
    } else {
      this.router.navigate(['/projets/' + projet.id + '/projet-info-general']);
    }
  }

  assignProjetsToComite(): void {
    this.performComiteActionOnSelectedProjets('Le(s) projet(s) ont été affectés au comité avec succès', AssignProjetComiteModalComponent);
  }

  setProjetsDateEnvoi(): void {
    this.performComiteActionOnSelectedProjets(
      `La date d'envoi pour validation par l'Etat a été ajoutée avec succès`,
      DateEnvoiModalComponent
    );
  }

  setProjetsDecision(): void {
    this.performComiteActionOnSelectedProjets(
      `La décision et date de validation par l'Etat ont été ajoutées avec succès`,
      DecisionComiteModalComponent
    );
  }

  private performComiteActionOnSelectedProjets(successMessage: string, dialogComponent: any): void {
    if (!this.selection.selected?.length) {
      this.showToastrService.error('Merci de sélectionner au moins un projet.');
      return;
    }

    const dialogRef = this.dialog.open(dialogComponent, {
      data: {
        projetsIds: this.selection.selected.map(projet => projet.id),
        codeAap: this.aap.code,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showToastrService.success(successMessage);
      }
    });
  }

  protected readonly EnumProjetEtape = EnumProjetEtape;
  protected readonly ToggleValues = ToggleValues;

  canSeeProjetToggleAction() {
    if (this.user)
      return (
        PermissionUtils.hasPermission(this.user, EnumPermissionUtilisateur.PROJET_WRITE) ||
        PermissionUtils.hasPermissionOnAap(this.user, EnumPermissionUtilisateur.PROJET_DATE_ECHEANCE_WRITE, this.aap)
      );

    return false;
  }
}

enum ToggleValues {
  TOUS_PROJETS = 'Tous les projets',
  MES_PROJETS = 'Mes projets',
}

import { EnumHabilitation } from '../enums/enum.habilitation';
import { EnumPermissionUtilisateur } from '../enums/enum.permissionUtilisateur';
import { OrganismeAapInterface } from '../interfaces/organismeAap.interface';
import { Departement } from './departement.model';

export class OrganismeAap implements OrganismeAapInterface {
  idOrganisme: string;
  codeThematiques: string[];
  nomOrganisme?: string;
  departements?: Departement[];
  permissions?: EnumPermissionUtilisateur[];
  habilitations?: EnumHabilitation[];

  constructor(
    idOrganisme: string,
    codeThematiques: string[],
    permissions?: EnumPermissionUtilisateur[],
    habilitations?: EnumHabilitation[]
  ) {
    this.idOrganisme = idOrganisme;
    this.codeThematiques = codeThematiques;
    this.permissions = permissions;
    this.habilitations = habilitations;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DocumentationComponent } from './documentation.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
  declarations: [DocumentationComponent],
  exports: [DocumentationComponent],
  providers: [],
})
export class DocumentationModule {}

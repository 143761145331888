<section class="form-step">
  <div id="anchor10" class="form-step__anchor"></div>
  <header class="form-step__header">
    <h2 class="form-step__title">
      <span class="form-step__number">{{ stepNumber }}</span>
      Catégories
    </h2>
  </header>

  <form id="categories" [formGroup]="categoriesForm">
    <div class="form-step__section section-secteur">
      <h3 class="form-step__subtitle">Secteurs</h3>
      <!-- Présence de secteurs-->
      <div id="input-secteurs" class="form-step__secteurs">
        <p class="radio-group-title">Présence de Secteurs <span class="ds-input-group__label-asterisk"> *</span></p>
        <div class="ds-radio-group">
          <input
            type="radio"
            name="secteursPresent"
            id="secteurs-true"
            class="ds-radio-group__radio-input"
            [value]="true"
            formControlName="secteursPresent"
          />
          <label for="secteurs-true" class="ds-radio-group__radio-label">Oui</label>

          <input
            type="radio"
            name="secteursPresent"
            id="secteurs-false"
            class="ds-radio-group__radio-input"
            [value]="false"
            formControlName="secteursPresent"
          />
          <label for="secteurs-false" class="ds-radio-group__radio-label">Non</label>
        </div>
      </div>
      <!-- FIN de Présence de secteurs -->

      <!-- Obligation de secteurs-->
      <div id="input-secteurs" class="form-step__secteurs-obligatoire">
        <p class="radio-group-title">Obligation de Secteurs <span class="ds-input-group__label-asterisk"> *</span></p>
        <div class="ds-radio-group">
          <input
            type="radio"
            name="secteursObligatoire"
            id="secteurs-obligatoire-true"
            class="ds-radio-group__radio-input"
            [value]="true"
            formControlName="secteursObligatoire"
            [attr.disabled]="!categoriesForm.controls['secteursPresent'].value === true ? true : null"
          />
          <label
            [ngClass]="{ 'radio-disabled': !categoriesForm.controls['secteursPresent'].value === true }"
            for="secteurs-obligatoire-true"
            class="ds-radio-group__radio-label"
            >Oui</label
          >

          <input
            type="radio"
            name="secteursObligatoire"
            id="secteurs-obligatoire-false"
            class="ds-radio-group__radio-input"
            [value]="false"
            formControlName="secteursObligatoire"
            [attr.disabled]="!categoriesForm.controls['secteursPresent'].value === true ? true : null"
          />
          <label
            [ngClass]="{ 'radio-disabled': !categoriesForm.controls['secteursPresent'].value === true }"
            for="secteurs-obligatoire-false"
            class="ds-radio-group__radio-label"
            >Non</label
          >
        </div>
      </div>
      <!-- FIN de Obligation de secteurs -->

      <!-- Liste des secteurs -->
      <div
        class="form-step__liste-secteurs ds-input-group"
        [class.ds-input-group--error]="
          displaySecteursInput && !categoriesForm.controls['secteurs']?.valid && categoriesForm.controls['secteurs']?.touched
        "
      >
        <label for="liste_secteurs" class="ds-input-group__label"
          >Liste des Secteurs<span class="ds-input-group__label-asterisk" *ngIf="displaySecteursInput && displaySecteursObligatoireInput">
            *</span
          ></label
        >

        <div
          class="ds-select ds-select-group"
          [class.ds-select--erreur]="
            displaySecteursInput && !categoriesForm.controls['secteurs']?.valid && categoriesForm.controls['secteurs']?.touched
          "
          *ngIf="dataListSecteurs.length"
        >
          <lib-ds-multiselect
            #secteursComponent
            class="filter"
            [dataList]="dataListSecteurs"
            [selectOptions]="{ placeholder: 'Sélectionner', isDisabled: !displaySecteursInput }"
            [name]="'liste_secteurs'"
            (click)="checkSecteurs()"
          >
          </lib-ds-multiselect>
        </div>
        <ng-container
          *ngIf="displaySecteursInput && !categoriesForm.controls['secteurs']?.valid && categoriesForm.controls['secteurs']?.touched"
        >
          <div class="ds-input-group__feedback">Merci de renseigner le/les secteurs</div>
        </ng-container>
      </div>
      <!-- FIN de Liste des secteurs -->
    </div>

    <div class="form-step__section section-domaines">
      <!-- Présence de domaines-->
      <h3 class="form-step__subtitle">Domaines</h3>
      <div id="input-domaines" class="form-step__domaines">
        <p class="radio-group-title">Présence de Domaines <span class="ds-input-group__label-asterisk"> *</span></p>
        <div class="ds-radio-group">
          <input
            type="radio"
            name="domainePresent"
            id="domaines-true"
            class="ds-radio-group__radio-input"
            [value]="true"
            formControlName="domainePresent"
          />
          <label for="domaines-true" class="ds-radio-group__radio-label">Oui</label>

          <input
            type="radio"
            name="domainePresent"
            id="domaines-false"
            class="ds-radio-group__radio-input"
            [value]="false"
            formControlName="domainePresent"
          />
          <label for="domaines-false" class="ds-radio-group__radio-label">Non</label>
        </div>
      </div>
      <!-- FIN de Présence de domaines -->

      <!-- Obligation de domaines-->
      <div id="input-domaines" class="form-step__domaines-obligatoire">
        <p class="radio-group-title">Obligation de Domaines <span class="ds-input-group__label-asterisk"> *</span></p>
        <div class="ds-radio-group">
          <input
            type="radio"
            name="domaineObligatoire"
            id="domaines-obligatoire-true"
            class="ds-radio-group__radio-input"
            [value]="true"
            formControlName="domaineObligatoire"
            [attr.disabled]="!categoriesForm.controls['domainePresent'].value === true ? true : null"
          />
          <label
            [ngClass]="{ 'radio-disabled': !categoriesForm.controls['domainePresent'].value === true }"
            for="domaines-obligatoire-true"
            class="ds-radio-group__radio-label"
            >Oui</label
          >

          <input
            type="radio"
            name="domaineObligatoire"
            id="domaines-obligatoire-false"
            class="ds-radio-group__radio-input"
            [value]="false"
            formControlName="domaineObligatoire"
            [attr.disabled]="!categoriesForm.controls['domainePresent'].value === true ? true : null"
          />
          <label
            [ngClass]="{ 'radio-disabled': !categoriesForm.controls['domainePresent'].value === true }"
            for="domaines-obligatoire-false"
            class="ds-radio-group__radio-label"
            >Non</label
          >
        </div>
      </div>
      <!-- FIN de Obligation de domaines -->

      <!-- Liste des domaines -->
      <div
        class="form-step__liste-domaines ds-input-group"
        [class.ds-input-group--error]="
          displayDomainesInput && !categoriesForm.controls['domaines']?.valid && categoriesForm.controls['domaines']?.touched
        "
      >
        <label for="liste_domaines" class="ds-input-group__label"
          >Liste des Domaines<span class="ds-input-group__label-asterisk" *ngIf="displayDomainesInput && displayDomainesObligatoireInput">
            *</span
          ></label
        >

        <div
          class="ds-select ds-select-group"
          [class.ds-select--erreur]="
            displayDomainesInput && !categoriesForm.controls['domaines']?.valid && categoriesForm.controls['domaines']?.touched
          "
          *ngIf="dataListDomaines.length"
        >
          <lib-ds-multiselect
            #domainesComponent
            class="filter"
            [dataList]="dataListDomaines"
            [selectOptions]="{ placeholder: 'Sélectionner', isDisabled: !displayDomainesInput }"
            [name]="'liste_domaines'"
            (click)="checkDomaines()"
          >
          </lib-ds-multiselect>
        </div>
        <ng-container
          *ngIf="displayDomainesInput && !categoriesForm.controls['domaines']?.valid && categoriesForm.controls['domaines']?.touched"
        >
          <div class="ds-input-group__feedback">Merci de renseigner le/les domaines</div>
        </ng-container>
      </div>
      <!-- FIN de Liste des domaines -->
    </div>

    <div class="form-step__section section-thematiques">
      <h3 class="form-step__subtitle">Thématiques</h3>
      <!-- Présence de thematiques-->
      <div id="input-thematiques" class="form-step__thematiques">
        <p class="radio-group-title">Présence de Thématiques <span class="ds-input-group__label-asterisk"> *</span></p>
        <div class="ds-radio-group">
          <input
            type="radio"
            name="thematiquesPresent"
            id="thematiques-true"
            class="ds-radio-group__radio-input"
            [value]="true"
            formControlName="thematiquesPresent"
          />
          <label for="thematiques-true" class="ds-radio-group__radio-label">Oui</label>

          <input
            type="radio"
            name="thematiquesPresent"
            id="thematiques-false"
            class="ds-radio-group__radio-input"
            [value]="false"
            formControlName="thematiquesPresent"
          />
          <label for="thematiques-false" class="ds-radio-group__radio-label">Non</label>
        </div>
      </div>
      <!-- FIN de Présence de thematiques -->

      <!-- Obligation de thematiques-->
      <div id="input-thematiques" class="form-step__thematiques-obligatoire">
        <p class="radio-group-title">Obligation de Thematiques <span class="ds-input-group__label-asterisk"> *</span></p>
        <div class="ds-radio-group">
          <input
            type="radio"
            name="thematiquesObligatoire"
            id="thematiques-obligatoire-true"
            class="ds-radio-group__radio-input"
            [value]="true"
            formControlName="thematiquesObligatoire"
            [attr.disabled]="!categoriesForm.controls['thematiquesPresent'].value === true ? true : null"
          />
          <label
            [ngClass]="{ 'radio-disabled': !categoriesForm.controls['thematiquesPresent'].value === true }"
            for="thematiques-obligatoire-true"
            class="ds-radio-group__radio-label"
            >Oui</label
          >

          <input
            type="radio"
            name="thematiquesObligatoire"
            id="thematiques-obligatoire-false"
            class="ds-radio-group__radio-input"
            [value]="false"
            formControlName="thematiquesObligatoire"
            [attr.disabled]="!categoriesForm.controls['thematiquesPresent'].value === true ? true : null"
          />
          <label
            [ngClass]="{ 'radio-disabled': !categoriesForm.controls['thematiquesPresent'].value === true }"
            for="thematiques-obligatoire-false"
            class="ds-radio-group__radio-label"
            >Non</label
          >
        </div>
      </div>
      <!-- FIN de Obligation de secteurs -->

      <!-- Liste des thematiques -->
      <div id="liste-thematiques" class="form-step__liste-thematiques">
        <mat-label class="fw-bold"
          >Thématiques<span class="ds-input-group__label-asterisk" *ngIf="displayThematiquesInput && displayThematiquesObligatoireInput">
            *</span
          ></mat-label
        >
        <mat-form-field class="mt-2" appearance="outline">
          <input
            placeholder="Nouvelle thematique..."
            [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
          />

          <mat-chip-grid #chipGrid aria-label="Entrer une thématique"> </mat-chip-grid>
        </mat-form-field>

        <mat-chip-row *ngFor="let thematique of thematiques" (removed)="remove(thematique)" [value]="thematique">
          {{ thematique.libelleThematique }}
          <button matChipRemove [attr.aria-label]="'remove ' + thematique">
            <lib-pxl-icon class="icon" icon="icon-action-cancel"></lib-pxl-icon>
          </button>
        </mat-chip-row>
      </div>
      <!-- FIN de Liste des thematiques -->
    </div>
  </form>

  <!-- organismes -->
  <ng-container *ngIf="displayAssociationThematiques">
    <div class="d-flex mt-2 mb-2">
      <div class="w-50"><label class="ds-input-group__label">Organisme</label></div>
      <div class="w-50">
        <label class="ds-input-group__label">Thematique<lib-asterisk></lib-asterisk></label>
      </div>
    </div>
    <div *ngFor="let organismeThematique of organismesThematiques; let i = index" class="d-flex mb-2">
      <div class="w-50 organismeLabel me-2 p-2">{{ organismeThematique.nomOrganisme }}</div>
      <div class="w-50">
        <mat-select
          [formControl]="organismesThematiquesFormGroup.get(organismeThematique.idOrganisme)"
          class="mat-multi-select p-1"
          (selectionChange)="changeThematique($event.value, organismeThematique.idOrganisme)"
          id="thematiqueValue"
          name="thematiqueValue"
          placeholder="Thematiques"
          multiple
        >
          <option [ngValue]="null" selected disabled hidden>Renseigner une/des thematiques</option>
          <mat-option *ngFor="let thematique of thematiques" value="{{ thematique.codeThematique }}">
            {{ thematique.libelleThematique }}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </ng-container>
  <!-- FIN organismes -->
</section>

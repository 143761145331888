import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ProjetService } from '@services-evaluateur/projet.service';
import {
  DsMultiselectComponent,
  FilterDataInterface,
  FilterRsSiretInterface,
  MultiselectComponent,
  SearchObject,
  SelectedSearchValues,
  ShowToastrService,
} from '@shared-ui';

@Component({
  selector: 'project-search',
  templateUrl: './projectSearch.component.html',
  styleUrls: ['./projectSearch.component.scss'],
})
export class ProjectSearchComponent implements OnInit {
  readonly ACRONYME = 'acronymes';
  readonly RS_SIRET = 'sirets';

  // Event emitter
  @Output() searchEventEmitter = new EventEmitter<any>();

  // Données filtres
  @Input() thematiqueDataList: FilterDataInterface[] = [];
  @Input() releveDataList: FilterDataInterface[] = [];
  @Input() etapeDataList: FilterDataInterface[] = [];
  @Input() statutDataList: FilterDataInterface[] = [];
  @Input() tacheDataList: FilterDataInterface[] = [];
  @Input() drdlDataList: FilterDataInterface[] = [];
  @Input() comiteDataList: FilterDataInterface[] = [];
  @Input() phaseDecisionDataList: FilterDataInterface[] = [];
  @Input() aapId: string;
  @Input() affectedOnly = false;

  @ViewChild('acronymeComponent') acronymeComponent: MultiselectComponent;
  @ViewChild('rsSiretComponent') rsSiretComponent: MultiselectComponent;
  @ViewChild('thematiqueComponent') thematiqueComponent: DsMultiselectComponent;
  @ViewChild('releveComponent') releveComponent: DsMultiselectComponent;
  @ViewChild('etapeComponent') etapeComponent: DsMultiselectComponent;
  @ViewChild('statutComponent') statutComponent: DsMultiselectComponent;
  @ViewChild('tachesComponent') tachesComponent: DsMultiselectComponent;
  @ViewChild('drdlComponent') drdlComponent: DsMultiselectComponent;
  @ViewChild('comiteComponent') comiteComponent: DsMultiselectComponent;
  @ViewChild('phaseDecisionComponent') phaseDecisionComponent: DsMultiselectComponent;

  // Données acronyme
  acronymesDataList: FilterDataInterface[] = [];
  acronymesSelectedList: FilterDataInterface[] = [];

  // Données Rs Siret
  rsSiretDataList: FilterDataInterface[] = [];
  rsSiretSelectedList: FilterDataInterface[] = [];

  // Afficher ou non la deuxième ligne de filtres
  showHiddenRows = true;

  // Chargement
  loadingMap = new Map();

  tooltiptext: string = null;

  constructor(private projetService: ProjetService, private toastrService: ShowToastrService) {
    this.loadingMap.set(this.ACRONYME, false);
    this.loadingMap.set(this.RS_SIRET, false);
  }

  ngOnInit(): void {
    if (localStorage.getItem('selectedSearchValues')) {
      const selectedSearchValues: SelectedSearchValues = JSON.parse(localStorage.getItem('selectedSearchValues'));

      this.acronymesSelectedList = selectedSearchValues.acronymes;
      this.rsSiretSelectedList = selectedSearchValues.siretRaisonSociale;

      this.checkSelectedValues(this.thematiqueDataList, selectedSearchValues.thematiques);
      this.checkSelectedValues(this.releveDataList, selectedSearchValues.datesReleves);
      this.checkSelectedValues(this.etapeDataList, selectedSearchValues.etapes);
      this.checkSelectedValues(this.statutDataList, selectedSearchValues.statuts);
      this.checkSelectedValues(this.tacheDataList, selectedSearchValues.taches);
      this.checkSelectedValues(this.drdlDataList, selectedSearchValues.drdl);
      this.checkSelectedValues(this.comiteDataList, selectedSearchValues.idComites);
      this.checkSelectedValues(this.phaseDecisionDataList, selectedSearchValues.phasesDecision);
    }

    this.tooltiptext = ` Remonte tous les projets dont le code postal du lieu de réalisation de la structure chef de file correspond aux codes postaux des DR/DL Bpifrance sélectionnées ou si une DR/DL a été forcée manuellement sur le projet.`;
  }

  /**
   * Récupère l'input du composant fils, lance la recherche d'autocomplete, ensuite actualise la liste des 10 options
   * @param event Event envoyé par le composant fils de filtre, soit le texte écrit dans l'input
   * @param source Le nom du composant fils qui a emit cet event
   */
  autocompleteEvent(event: string, source: string): void {
    this.loadingMap.set(source, true);
    const searchObject = this.getCurrentSearchObject();
    searchObject.idAAPs = [this.aapId];
    switch (source) {
      case this.ACRONYME:
        searchObject.inputAcronyme = event;
        break;
      case this.RS_SIRET:
        searchObject.inputSiretRS = event;
        break;
    }
    searchObject.affectedOnly = this.affectedOnly;
    this.projetService.getAutocomplete(source, searchObject).subscribe({
      next: resultAutocomplete => {
        switch (source) {
          case this.ACRONYME:
            this.acronymesDataList = (resultAutocomplete.body as string[]).map(acronyme => ({ id: acronyme, value: acronyme }));
            break;
          case this.RS_SIRET:
            this.rsSiretDataList = (resultAutocomplete.body as FilterRsSiretInterface[]).map(rsSiret => ({
              id: rsSiret,
              value: (rsSiret.siret != null && rsSiret.siret?.trim() !== '' ? rsSiret.siret + ' - ' : '') + rsSiret.raisonSocial,
            }));
            break;
        }
        this.loadingMap.set(source, false);
      },
      error: (err: HttpErrorResponse) => {
        this.toastrService.checkCodeError(err.error.code);
        this.loadingMap.set(source, false);
      },
    });
  }

  /**
   * Récupère la nouvelle valeur de la liste d'options sélectionnées sur le composant fils
   * @param event Event envoyé par le composant fils de filtre, soit la liste des options sélectionnées sur le composant
   * @param source Le nom du composant fils qui a emit cet event
   */
  selectedListChange(event: FilterDataInterface[], source: string): void {
    switch (source) {
      case this.ACRONYME:
        this.acronymesSelectedList = event;
        break;
      case this.RS_SIRET:
        this.rsSiretSelectedList = event;
        break;
    }
  }

  /**
   * Remet à zéro tous les champs de filtres
   */
  resetAllFilters(): void {
    this.acronymeComponent.reset();
    this.rsSiretComponent.reset();
    this.thematiqueComponent.reset();
    this.releveComponent.reset();
    this.etapeComponent.reset();
    this.statutComponent.reset();
    this.tachesComponent.reset();
    this.drdlComponent.reset();
    this.comiteComponent.reset();
    this.phaseDecisionComponent.reset();
  }

  getCurrentSearchObject(): SearchObject {
    const searchObject = new SearchObject();
    searchObject.acronymes = this.acronymesSelectedList.map(acronyme => acronyme.id as string);
    searchObject.siretRaisonSociale = this.rsSiretSelectedList.map(rs => ({
      siret: (rs.id as FilterRsSiretInterface).siret,
      raisonSocial: (rs.id as FilterRsSiretInterface).raisonSocial,
    }));
    searchObject.thematiques = this.thematiqueComponent.getSelectedValues().map(thematique => thematique.id as string);
    searchObject.datesReleves = this.releveComponent.getSelectedValues().map(releve => releve.id as string);
    searchObject.etapes = this.etapeComponent.getSelectedValues().map(etape => etape.id as string);
    searchObject.statuts = this.statutComponent.getSelectedValues().map(statut => statut.id as string);
    searchObject.taches = this.tachesComponent.getSelectedValues()?.map(tache => tache.id as string);
    searchObject.drdl = this.drdlComponent.getSelectedValues().map(drdl => drdl.id as string);
    searchObject.idComites = this.comiteComponent.getSelectedValues().map(comite => comite.id as string);
    searchObject.phasesDecision = this.phaseDecisionComponent.getSelectedValues().map(phaseDecision => phaseDecision.id as string);

    return searchObject;
  }

  submitSearch(): void {
    localStorage.setItem('filtersSearchObject', JSON.stringify(this.getCurrentSearchObject()));

    const selectedSearchValues: SelectedSearchValues = new SelectedSearchValues();

    selectedSearchValues.acronymes = this.acronymesSelectedList;
    selectedSearchValues.siretRaisonSociale = this.rsSiretSelectedList;
    selectedSearchValues.thematiques = this.thematiqueComponent.getSelectedValues();
    selectedSearchValues.datesReleves = this.releveComponent.getSelectedValues();
    selectedSearchValues.etapes = this.etapeComponent.getSelectedValues();
    selectedSearchValues.statuts = this.statutComponent.getSelectedValues();
    selectedSearchValues.taches = this.tachesComponent.getSelectedValues();
    selectedSearchValues.drdl = this.drdlComponent.getSelectedValues();
    selectedSearchValues.idComites = this.comiteComponent.getSelectedValues();
    selectedSearchValues.phasesDecision = this.phaseDecisionComponent.getSelectedValues();

    localStorage.setItem('selectedSearchValues', JSON.stringify(selectedSearchValues));

    this.searchEventEmitter.emit(this.getCurrentSearchObject());
  }

  checkSelectedValues(dataList: FilterDataInterface[], selectedValues: FilterDataInterface[]): void {
    dataList.forEach((item: FilterDataInterface) => {
      if (selectedValues.find(selectedItem => selectedItem.id === item.id)) {
        item.isSelected = true;
      }
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { Feature, PermissionUtils, ShowToastrService } from '@shared-ui';
import { firstValueFrom, Observable } from 'rxjs';
import { PxlOidcService } from '@services-evaluateur/pxl-oidc.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';

@Injectable()
export class AppConfig {
  public features: Feature[] = [];
  public isLoaded = false;

  constructor(
    private router: Router,
    private utilisateurService: UtilisateurService,
    private permissionsService: NgxPermissionsService,
    private oidcSecurityService: PxlOidcService,
    private http: HttpClient,
    private showToastrService: ShowToastrService
  ) {}

  async load(): Promise<void> {
    try {
      const loginResponse = await firstValueFrom(this.oidcSecurityService.checkAuth());
      const isAuthenticated = loginResponse?.isAuthenticated || environment.stubAuth;
      if ('/autologin' === window.location.pathname) {
        this.isLoaded = true;
        return;
      }
      if (!isAuthenticated) {
        localStorage.setItem('redirect', window.location.pathname + window.location.search);
        this.oidcSecurityService.authorize(localStorage.getItem('idp') as string);
        return;
      }
    } catch (err: any) {
      if (err?.error) {
        this.showToastrService.checkCodeError(err?.error);
      } else {
        this.showToastrService.error(`An error occurred while checking authentication: ${JSON.stringify(err)}`);
      }
    }
    // load feature flippings
    try {
      this.features = await firstValueFrom(this.getFeatures());
    } catch (err) {
      this.showToastrService.error(`Could not load features: ${JSON.stringify(err)}`);
    }
    // load user and permissions
    const user = await firstValueFrom(this.utilisateurService.getUtilisateur());
    this.permissionsService.addPermission(PermissionUtils.getGlobalPermissions(user));

    this.isLoaded = true;
    await this.goToApp();
  }

  getFeatures(): Observable<Feature[]> {
    return this.http.get<Feature[]>(`${environment.apiUrl}features?site=EVAL`);
  }

  private async goToApp(): Promise<void> {
    let path = localStorage.getItem('redirect');
    if (path) {
      localStorage.removeItem('redirect');
      if (path.toString().includes('/unauthorized') || path.toString().includes('/?idp=')) {
        path = '/';
      }
      await this.router.navigateByUrl(path);
    }
  }
}

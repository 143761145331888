import { Component, OnInit } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { AppConfig } from '../app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'pxl_front_evaluateur';
  showNavBar = true;
  featureName = true;

  constructor(private router: Router, public appConfig: AppConfig) {}

  ngOnInit(): void {
    localStorage.removeItem('filtersSearchObject');
    localStorage.removeItem('selectedSearchValues');

    this.router.events.subscribe(data => {
      if (data instanceof ActivationStart) {
        const { showNavBar, featureName } = data.snapshot.data;
        this.showNavBar = !(showNavBar === false);

        if (featureName === false) {
          this.featureName = featureName;
        }
      }
    });
  }
}

<div>
  <div id="title-holder">
    <span>Choisir mon parcours évaluateur</span>
  </div>
  <div id="choice-holder">
    <div class="espace-selection-button espace-selection-button-enabled" (click)="redirectMembreOrganisme()">
      <div class="button-label">
        <span
          >{{ hasMembreOrganismeAccess ? 'Accéder' : 'Demander mon accès' }} en tant que membre
          {{ hasMembreOrganismeAccess ? evaluateur.organisme?.nom : "d'organisme" }}</span
        >
      </div>
      <div class="button-subtitle">
        <span class="button-subtitle-text">Cet est espace est dédié aux membres des organismes et opérateurs associés à Bpifrance.</span>
      </div>
      <div class="button-creation-project">
        <button id="access-instructeur" class="ds-btn ds-btn--tertiary">
          <lib-pxl-icon class="icon btn-icon" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
          Accéder
        </button>
      </div>
    </div>
    <div class="espace-selection-button" (click)="redirectEvaluateurExterne()" [class.espace-selection-button-enabled]="invitationConsumee">
      <div class="button-label">
        <span
          >{{ invitationConsumee ? 'Accéder' : 'Validez votre invitation reçue par email pour accéder' }} en tant qu'expert externe</span
        >
      </div>
      <div class="button-subtitle">
        <span class="button-subtitle-text"
          >Cet espace est dédié aux évaluateurs externes tels que les experts indépendants, les experts ministériels, les superviseurs, les
          contacts des pôles labellisateurs et les experts externes. Cet espace est aussi ouvert aux coordinateurs CPMO et aux SGPI.</span
        >
      </div>
      <div class="button-creation-project" *ngIf="invitationConsumee">
        <button id="access-evaluateur" class="ds-btn ds-btn--tertiary">
          <lib-pxl-icon class="icon btn-icon" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
          Accéder
        </button>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectSearchTransverseComponent } from './project-search-transverse.component';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [ProjectSearchTransverseComponent],
  imports: [CommonModule, SharedModule, SharedUiModule],
  exports: [ProjectSearchTransverseComponent],
})
export class ProjectSearchTransverseModule {}

import { EnumHabilitation } from '../enums/enum.habilitation';
import { EnumPermissionUtilisateur } from '../enums/enum.permissionUtilisateur';
import { OrganismeAapInterface } from '../interfaces/organismeAap.interface';
import { EnumProjetStatut } from '../enums/enum.projetStatut';
import { EnumProjetEtape } from '../enums/enumProjetEtape';

export class OrganismeAap implements OrganismeAapInterface {
  idOrganisme: string;
  codeThematiques: string[];
  departements?: string[];
  premiereEtapeProjetAccessible: EnumProjetEtape;
  premierStatutProjetAccessible: EnumProjetStatut;
  permissions?: EnumPermissionUtilisateur[];
  habilitations?: EnumHabilitation[];
  nom?: string;

  constructor(
    idOrganisme: string,
    codeThematiques: string[],
    departements: string[],
    premiereEtapeProjetAccessible: EnumProjetEtape,
    premierStatutProjetAccessible: EnumProjetStatut,
    permissions: EnumPermissionUtilisateur[],
    habilitations: EnumHabilitation[],
    nom?: string
  ) {
    this.idOrganisme = idOrganisme;
    this.codeThematiques = codeThematiques;
    this.departements = departements;
    this.premiereEtapeProjetAccessible = premiereEtapeProjetAccessible;
    this.premierStatutProjetAccessible = premierStatutProjetAccessible;
    this.permissions = permissions;
    this.habilitations = habilitations;
    this.nom = nom;
  }
}

import { DOCUMENT } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProfilEvaluateur } from '@core-evaluateur/profil.guard';
import { environment } from '@environments-evaluateur/environment';
import { CHOSEN_PROFILE_KEY } from '@features-evaluateur/acces-evaluateur/evaluateur-choice/evaluateur-choice.component';
import { AapService } from '@services-evaluateur/aap.service';
import { EvaluateurService } from '@services-evaluateur/evaluateur.service';
import { PxlOidcService } from '@services-evaluateur/pxl-oidc.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import {
  Aap,
  DsMultiselectComponent,
  EnumAapStatut,
  EnumPermissionUtilisateur,
  EnumPositionnement,
  Evaluateur,
  FilterDataInterface,
  PermissionUtils,
  ShowToastrService,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';

@Component({
  selector: 'pxl-aap-list',
  templateUrl: './aap.list.component.html',
  styleUrls: ['./aap.list.component.scss'],
})
export class AapListComponent extends SubscriptionDestroyerComponent implements OnInit {
  @ViewChild('AAPTypeComponent') aapTypeComponent: DsMultiselectComponent;
  displayedColumns: string[] = ['programme.type', 'codeFront', 'statut', 'dateOuverture', 'dateFermeture'];
  dataSource: MatTableDataSource<Aap>;
  @ViewChild(MatSort) sort: MatSort;
  evaluateur: Evaluateur;
  user: Utilisateur;
  displayAAPTypeInput = true;
  dataListAAPType: FilterDataInterface[] = [
    { id: '0', value: 'AAPs ouverts', isSelected: false },
    { id: '1', value: 'AAPs fermés', isSelected: false },
    { id: '2', value: 'AAPs brouillons', isSelected: false },
    { id: '3', value: 'AAPs Immersion', isSelected: false },
  ];

  showTransverseSearch = false;
  isDoubleProfile = localStorage.getItem('DOUBLE_PROFILE') === 'true';

  EnumAapStatut = EnumAapStatut;

  constructor(
    private router: Router,
    private aapService: AapService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public sharedFunction: SharedFunction,
    public dialog: MatDialog,
    private oidcSecurityService: PxlOidcService,
    private evaluateurService: EvaluateurService,
    public showToastrService: ShowToastrService,
    public utilisateurService: UtilisateurService
  ) {
    super();
  }

  ngOnInit(): void {
    localStorage.removeItem('filtersSearchObject');
    localStorage.removeItem('selectedSearchValues');
    this.utilisateurService.getUtilisateurObservable().pipe(this.takeUntilDestroyed()).subscribe(this.onUserUpdate.bind(this));
    this.renderer.addClass(this.document.body, 'nav-collapsed');

    this.oidcSecurityService.isAuthenticated$?.subscribe(({ isAuthenticated }) => {
      if (isAuthenticated) {
        this.evaluateurService
          .getEvaluateurObservable()
          .pipe(this.takeUntilDestroyed())
          .subscribe(response => {
            this.evaluateur = response?.body;
          });
        this.loadAaps(localStorage.getItem(CHOSEN_PROFILE_KEY) === ProfilEvaluateur.MEMBRE_ORGANISME);
      }
    });
  }

  loadAaps(isMembreOrganisme?: boolean): void {
    this.aapService.getAaps(isMembreOrganisme).subscribe((response: HttpResponse<Aap[]>) => {
      this.dataSource = new MatTableDataSource(response?.body);

      // Indique à la fonction de tri comment acceder au propriétés de l'objet utilisé pour le tri
      // ceci se fait parce que la propriété de l'objet element du html ne correspond pas au nom de la colonne
      this.dataSource.sortingDataAccessor = this.getSortingDataAccessor();
      if (this.sort) {
        this.sort.disableClear = true;
      }

      this.dataSource.sort = this.sort;
      this.combineFilters();
    });
  }

  private getSortingDataAccessor(): (item: Aap, property: string) => string {
    return (item, property) => {
      if (property === 'programme.type') {
        return item.programme?.type?.toLocaleLowerCase().trim();
      } else {
        return typeof (item as any)[property] === 'string' ? (item as any)[property]?.toLocaleLowerCase().trim() : (item as any)[property];
      }
    };
  }

  getProjets(id: string): void {
    this.router.navigate(['aaps/' + id + '/projets']);
  }

  createProg(): void {
    this.router.navigate(['creationAap']);
  }

  searchProjetsTransverseAaps(): void {
    this.router.navigate(['aaps-transverse/projets']);
  }

  onUserUpdate(user: Utilisateur) {
    this.user = user;
    this.showTransverseSearch = PermissionUtils.hasPermission(user, EnumPermissionUtilisateur.PROJET_GLOBAL_SEARCH);
  }

  /**
   * Redirige vers l'espace candidats
   */
  redirectToCandidats(): void {
    window.location.href = environment.espaceCandidat + '/projets';
  }

  combineFilters(): void {
    this.dataSource.filterPredicate = (data: Aap) => {
      if (!this.user.favoriteAapIds) {
        this.user.favoriteAapIds = [];
      }
      return this.isAapOfSelectedType(data);
    };
    this.dataSource.filter = 'apply';
  }

  isAapOfSelectedType(data: Aap): boolean {
    const aapFilters = this.aapTypeComponent.getSelectedValues();

    if (aapFilters.length === 0) {
      return true;
    }

    const conditions: { [key: string]: () => boolean } = {
      'AAPs brouillons': () => data.statut === EnumAapStatut.BROUILLON,
      'AAPs ouverts': () => data.statut === EnumAapStatut.OUVERT,
      'AAPs fermés': () => data.statut === EnumAapStatut.FERME,
      'AAPs Immersion': () => data.immersion,
    };

    return aapFilters.some(filterData => conditions[filterData.value]?.());
  }

  resetAllFilters(): void {
    this.aapTypeComponent.reset();
  }

  submitSearch(): void {
    this.combineFilters();
  }

  onClickGoToChoice(): void {
    localStorage.removeItem(CHOSEN_PROFILE_KEY);
    this.router.navigate(['evaluateur-choice']);
  }

  protected readonly EnumPositionnement = EnumPositionnement;
}

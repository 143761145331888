import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { PeriodicityComponent } from './periodicity.component';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { ConfirmModalModule } from '@shared-evaluateur/components/modals/confirm-modal/confirm-modal.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ModificationDatesModalModule } from '@shared-evaluateur/components/modals/modification-dates-modal/modification-dates-modal.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MaterialModule,
    ConfirmModalModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
    ModificationDatesModalModule,
  ],
  declarations: [PeriodicityComponent],
  exports: [PeriodicityComponent],
  providers: [],
})
export class PeriodicityModule {}

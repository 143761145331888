import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import {
  Aap,
  EnumFeatureFlipping,
  EnumHabilitation,
  EnumProjetEtape,
  EnumRoleStructure,
  EnumScope,
  EnumTypePartenaire,
  EnumTypeStructure,
  ReferentielDocumentsAap,
  ReferentielHttpService,
  ShowToastrService,
  SubscriptionDestroyerComponent,
} from '@shared-ui';
import { DocumentationHelperService } from './documentation.helper.service';
type Option = { id: string; value: string };
@Component({
  selector: 'pxl-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
})
export class DocumentationComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() documentationFormGroup: UntypedFormGroup;
  @Input() partenaireForm: UntypedFormGroup;
  @Input() stepNumber: number;
  @Input() set aap(value: Aap) {
    this._aap = value;
    this.updateModel();
  }
  readonly habilitation = [
    { id: 'noH', value: '∅ = Document accessible par tous' },
    {
      id: EnumHabilitation.H1,
      value: EnumHabilitation.H1 + ' = Document accessible à Bpifrance, au Superviseur AAP, Evaluateurs, aux Opérateurs associés',
    },
    { id: EnumHabilitation.H2, value: EnumHabilitation.H2 + ' = Document accessible à Bpifrance, aux Opérateurs associés instructeur' },
    {
      id: EnumHabilitation.H3,
      value:
        EnumHabilitation.H3 + ' = Document accessible à à Bpifrance, au Superviseur AAP, Evaluateur ministère, aux Opérateurs associés',
    },
    {
      id: EnumHabilitation.H4,
      value:
        EnumHabilitation.H4 +
        ' = Document accessible à Bpifrance, au Superviseur AAP, Evaluateurs, Evaluateurs ministère, aux Opérateurs associés, Experts externes',
    },
  ];

  etapeDataList: Option[] = [
    {
      id: EnumProjetEtape.PRE_DEPOT,
      value: 'Pré-dépôt',
    },
    {
      id: EnumProjetEtape.DEPOT,
      value: 'Dépôt',
    },
  ];
  documentsReferentiel: ReferentielDocumentsAap[] = [];
  documentsReferentielTmp: ReferentielDocumentsAap[] = [];

  displayedColumns: string[] = [
    'scope',
    'disponible',
    'depot',
    'nom',
    'obligatoire',
    'habilitationWKF',
    'structureProjet',
    'roles',
    'type',
  ];
  _aap: Aap;
  dataSource = new MatTableDataSource([]);

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private showToastrService: ShowToastrService,
    private referentielService: ReferentielHttpService
  ) {
    super();
  }

  placeholder = '';

  gedConfiguree: boolean;

  ngOnInit(): void {
    this.referentielService.getReferentielDocumentsAAP().subscribe({
      next: (response: HttpResponse<ReferentielDocumentsAap[]>) => {
        if (!response) return;
        this.documentsReferentielTmp = response.body;
        this.documentsReferentiel = response.body.filter(doc => doc.typePartenaires.includes(this.partenaireForm.get('type').value));
        this.updateModel();
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });

    this.partenaireForm?.valueChanges.subscribe(() => {
      this.documentsReferentiel = this.documentsReferentielTmp;
      this.documentsReferentiel = this.documentsReferentiel.filter(doc =>
        doc.typePartenaires.includes(this.partenaireForm.get('type').value)
      );
      this.updateModel();
    });
  }

  updateModel() {
    if (!this._aap) return;
    this.documentationFormGroup.setControl(
      'documentAaps',
      DocumentationHelperService.buildDocumentationFormArray(this.documentsReferentiel, this._aap)
    );
    this.dataSource = new MatTableDataSource(this.documentationFormGroup?.get('documentAaps').value);
  }

  onChangeScope(index: number) {
    const documentAaps = this.documentationFormGroup.get('documentAaps') as FormGroup;
    const documentAap = documentAaps.controls[index] as FormGroup;
    const scopeValue = documentAap.get('scope').value;

    const typeStructuresControl = documentAap.get('typeStructures');
    const roleStructuresControl = documentAap.get('roleStructures');

    if (scopeValue === EnumScope.STRUCTURE || scopeValue === EnumScope.STRUCTURE_ACTIONARIAL) {
      this.placeholder = 'Sélectionnez';

      typeStructuresControl.reset();
      typeStructuresControl.setValidators([Validators.required]);
      typeStructuresControl.enable();
      typeStructuresControl.updateValueAndValidity();

      roleStructuresControl.reset();
      roleStructuresControl.setValidators([Validators.required]);
      roleStructuresControl.enable();
      roleStructuresControl.updateValueAndValidity();
    } else {
      this.placeholder = '';
      typeStructuresControl.reset();
      typeStructuresControl.clearValidators();
      typeStructuresControl.disable();
      typeStructuresControl.updateValueAndValidity();

      roleStructuresControl.reset();
      roleStructuresControl.clearValidators();
      roleStructuresControl.disable();
      roleStructuresControl.updateValueAndValidity();
    }
  }

  protected readonly EnumScope = EnumScope;
  protected readonly EnumTypeStructure = EnumTypeStructure;
  protected readonly EnumFeatureFlipping = EnumFeatureFlipping;
  protected readonly EnumTypePartenaire = EnumTypePartenaire;
  protected readonly EnumRoleStructure = EnumRoleStructure;
}

import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-evaluateur/aap.service';
import {
  Aap,
  CritereReferentielAap,
  EnumAapStatut,
  EnumFeatureFlipping,
  EnumIntervenants,
  EnumTypePartenaire,
  FeatureFlagService,
  Kpi,
  KpiAap,
  OrganismeAap,
  ShowToastrService,
} from '@shared-ui';
import { Observable } from 'rxjs';
import { DOMAINES } from '../../../assets/json/domaines';
import { SECTEURS } from '../../../assets/json/secteurs';
import { CategoriesComponent } from './component/categories/categories.component';
import { DomainesSharedService } from './component/categories/domaines-shared.service';
import { SecteursSharedService } from './component/categories/secteurs-shared.service';
import { DocumentationHelperService } from './component/documentation/documentation.helper.service';
import { PolesComponent } from './component/poles/poles.component';

@Component({
  selector: 'pxl-program-creation',
  templateUrl: './aap.creation.component.html',
  styleUrls: ['./aap.creation.component.scss'],
})
export class AapCreationComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild(PolesComponent) pxlPoles: PolesComponent;
  @ViewChild(CategoriesComponent) pxlCategories: CategoriesComponent;

  readonly SECTEURS = SECTEURS;
  readonly DOMAINES = DOMAINES;

  aapForm: UntypedFormGroup;
  isCreatedAap = false;
  isEditingExistingAap = false;
  showForm = false;
  aap: Aap = new Aap();
  projetLength: number;

  activeAnchor = 'anchor1';
  headerHeight = 72;

  steps = [
    { label: 'Informations générales', section: 'anchor1', featureName: null },
    { label: 'Dates & périodicité', section: 'anchor2', featureName: null },
    { label: 'Structure du projet', section: 'anchor3', featureName: null },
    { label: "KPI grille d'impacts", section: 'anchor4', featureName: EnumFeatureFlipping.GRILLE_IMPACTS },
    { label: "Critères d'évaluation", section: 'anchor5', featureName: EnumFeatureFlipping.CRITERES_EVALUATION },
    { label: "Indicateurs d'éligibilité", section: 'anchor6', featureName: EnumFeatureFlipping.INDICATEURS_ELIGIBILITE },
    { label: 'Phases', section: 'anchor7', featureName: null },
    { label: 'Organismes', section: 'anchor8', featureName: EnumFeatureFlipping.ORGANISMES },
    { label: 'Pôles', section: 'anchor11', featureName: null },
    { label: 'Catégories', section: 'anchor9', featureName: null },
    { label: 'Documentation', section: 'anchor10', featureName: EnumFeatureFlipping.AAP_CONFIGURATION_DOCUMENT },
    { label: 'Documents dématérialisés', section: 'anchor12', featureName: null },
  ];

  constructor(
    private featureFlagService: FeatureFlagService,
    private aapService: AapService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public domainesSharedService: DomainesSharedService,
    public secteursSharedService: SecteursSharedService,
    private router: Router
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.aapForm.dirty;
  }

  public requiredListInput(formControl: any): any {
    return formControl.value && formControl.value.id
      ? null
      : {
          requiredList: {
            valid: false,
          },
        };
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public ngOnInit(): void {
    this.steps = this.steps
      .map(step => ({ ...step, featureOn: step?.featureName == null ? true : !this.featureFlagService.featureOff(step?.featureName) }))
      .filter(step => step.featureOn);

    this.secteursSharedService.setSecteursData(this.SECTEURS);
    this.domainesSharedService.setDomainesData(this.DOMAINES);

    this.document.getElementById('aap-creation').addEventListener('scroll', this.scrolling, true);

    this.renderer.addClass(this.document.body, 'nav-collapsed');
    this.createForms();

    if (this.route.snapshot.params.id) {
      this.isEditingExistingAap = true;
      this.aapService.getAapById(this.route.snapshot.params.id, true).subscribe((response: HttpResponse<Aap>) => {
        if (response) {
          this.aap = response.body;
          this.projetLength = this.aap.projets.length;
          this.setAapInitConfiguration();
          this.showForm = true;
        }
      });
      return;
    }

    this.showForm = true;
  }

  public createForms(): void {
    this.aapForm = this.formBuilder.group({
      informations: this.formBuilder.group({
        categorie: ['AAP', Validators.required],
        niveauInstruction: [EnumIntervenants.SIEGE, Validators.required],
        greAgre: [false, Validators.required],
        ilab: [false, Validators.required],
        budgetEstime: [null, Validators.required],
        immersion: [false, Validators.required],
        personnePhysique: [false, Validators.required],
        programme: [{}, this.requiredListInput],
        codeComptable: [null, Validators.required],
        code: [null],
        typeWKF: [null, Validators.required],
        nom: [null, Validators.required],
        dispositif: [null],
        dispositifsSecondaires: [[]],
        description: [null],
        logo: [null],
        bal: [null, [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$')]],
        lienDocumentation: [
          null,
          Validators.pattern(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/),
        ],
      }),
      notationEligibilite: this.formBuilder.group({
        isActive: [false, Validators.required],
      }),
      notationContractualisation: this.formBuilder.group({
        isActive: [false, Validators.required],
      }),
      notationEvaluation: this.formBuilder.group({
        notationBarre: [null, Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)],
        isActive: [false, Validators.required],
        template: [null],
        typeNote: [null],
      }),
      notationAudition: this.formBuilder.group({
        notationBarre: [null, Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)],
        isActive: [false, Validators.required],
        template: [null],
        typeNote: [null],
      }),
      notationProprieteIntellectuelle: this.formBuilder.group({
        isActive: [false, Validators.required],
      }),
      notationInstruction: this.formBuilder.group({
        isActive: [false, Validators.required],
        typeNote: [null],
      }),
      periodicity: this.formBuilder.group({
        mode: ['CONTINU', Validators.required],
        datePublicationJorf: [null],
        dateOuverture: [null, Validators.required],
        heureOuverture: [null, Validators.required],
        dateFermeture: [null, Validators.required],
        heureFermeture: [null, Validators.required],
        dateAutres: [null],
      }),
      partenaire: this.formBuilder.group({
        type: [EnumTypePartenaire.MULTI, Validators.required],
        obligatoire: [false, Validators.required],
      }),
      kpis: [[], null],
      criteresEvaluations: new FormArray([]),
      indicateursEligibilites: new FormArray([]),
      organismes: [[], null],
      poles: this.formBuilder.group({
        polePresent: [false, Validators.required],
        poleObligatoire: [false, Validators.required],
        poles: [null],
      }),
      categories: this.formBuilder.group({
        secteursPresent: [false, Validators.required],
        secteursObligatoire: [false, Validators.required],
        secteurs: [null],
        domainePresent: [false, Validators.required],
        domaineObligatoire: [false, Validators.required],
        domaines: [null],
        thematiquesPresent: [false, Validators.required],
        thematiquesObligatoire: [false, Validators.required],
        thematiques: [null],
      }),
      documentation: this.formBuilder.group({
        documentAaps: this.formBuilder.array([]),
        gedConfiguree: [true, Validators.required],
      }),
      demat: this.formBuilder.group({
        depensesProjetPresent: [false, Validators.required],
        ficheComPresent: [false, Validators.required],
        ficheDemandeAidePresent: this.formBuilder.group({
          active: [false, Validators.required],
          etape: [null],
        }),
      }),
    });
  }

  setAapInitConfiguration() {
    this.aap.greAgre = this.setAapPropertyNullToFalse(this.aap.greAgre);
    this.aap.ilab = this.setAapPropertyNullToFalse(this.aap.ilab);
    this.aap.budgetEstime = this.setAapPropertyNullToFalse(this.aap.budgetEstime);
    this.aap.immersion = this.setAapPropertyNullToFalse(this.aap.immersion);
    this.aap.personnePhysique = this.setAapPropertyNullToFalse(this.aap.personnePhysique);
    this.aap.polePresent = this.setAapPropertyNullToFalse(this.aap.polePresent);
    this.aap.poleObligatoire = this.setAapPropertyNullToFalse(this.aap.poleObligatoire);
    this.aap.secteursPresent = this.setAapPropertyNullToFalse(this.aap.secteursPresent);
    this.aap.secteursObligatoire = this.setAapPropertyNullToFalse(this.aap.secteursObligatoire);
    this.aap.domainePresent = this.setAapPropertyNullToFalse(this.aap.domainePresent);
    this.aap.domaineObligatoire = this.setAapPropertyNullToFalse(this.aap.domaineObligatoire);
    this.aap.thematiquesPresent = this.setAapPropertyNullToFalse(this.aap.thematiquesPresent);
    this.aap.thematiquesObligatoire = this.setAapPropertyNullToFalse(this.aap.thematiquesObligatoire);
    this.aap.depensesProjetPresent = this.setAapPropertyNullToFalse(this.aap.depensesProjetPresent);

    if (this.aap.statut !== EnumAapStatut.BROUILLON) {
      this.isCreatedAap = true;
    }

    if (this.aap.statut !== EnumAapStatut.BROUILLON) {
      this.aap.gedConfiguree = this.setAapPropertyNullToFalse(this.aap.gedConfiguree);
    } else {
      this.aap.gedConfiguree = this.setAapPropertyNullToTrue(this.aap.gedConfiguree);
    }

    if (this.aap.statut === EnumAapStatut.OUVERT) {
      this.aapForm.get('documentation')?.get('gedConfiguree').disable();
    }

    if (this.aap.code) {
      this.aap.codeComptable = 'Code inconnu';
    }

    let initDateOuverture = null;
    let initHeureOuverture = null;
    let initDateFermeture = null;
    let initHeureFermeture = null;
    if (this.aap.dateOuverture) {
      initDateOuverture = new Date(this.aap.dateOuverture);
      initHeureOuverture = ('0' + initDateOuverture.getHours()).slice(-2) + ':' + '00';
    }
    if (this.aap.dateFermeture) {
      initDateFermeture = new Date(this.aap.dateFermeture);
      initHeureFermeture = ('0' + initDateFermeture.getHours()).slice(-2) + ':' + '00';
    }

    this.aapForm.patchValue({
      informations: {
        categorie: this.aap.categorie,
        niveauInstruction: this.aap.niveauInstruction,
        greAgre: this.aap.greAgre,
        ilab: this.aap.ilab,
        budgetEstime: this.aap.budgetEstime,
        immersion: this.aap.immersion,
        personnePhysique: this.aap.personnePhysique,
        programme: this.aap.programme,
        codeComptable: this.aap.codeComptable,
        code: this.aap.code,
        typeWKF: this.aap.typeWKF,
        nom: this.aap.nom,
        dispositif: this.aap.dispositif ?? null,
        dispositifsSecondaires: this.aap.dispositifsSecondaires,
        description: this.aap.description,
        logo: this.aap.logo,
        bal: this.aap.bal,
        lienDocumentation: this.aap.lienDocumentation,
      },
      periodicity: {
        mode: 'CONTINU',
        datePublicationJorf: this.aap.datePublicationJorf,
        dateOuverture: initDateOuverture,
        heureOuverture: initHeureOuverture,
        dateFermeture: initDateFermeture,
        heureFermeture: initHeureFermeture,
        dateAutres: this.aap.dateAutres,
      },
      partenaire: {
        type: this.aap.partenaire.type,
        obligatoire: this.aap.partenaire.obligatoire,
      },
      notationEligibilite: {
        isActive: this.aap.eligibilitePresent,
      },
      notationContractualisation: {
        isActive: this.aap.contractualisationPresent,
      },
      notationEvaluation: {
        notationBarre: this.aap.notationBarreEvaluation,
        isActive: this.aap.notationEvaluationPresent,
        template: this.aap.notationEvaluationTemplate,
        typeNote: this.aap.notationEvaluationTypeNote,
      },
      notationAudition: {
        notationBarre: this.aap.notationBarreAudition,
        isActive: this.aap.notationAuditionPresent,
        template: this.aap.notationAuditionTemplate,
        typeNote: this.aap.notationAuditionTypeNote,
      },
      notationProprieteIntellectuelle: {
        isActive: this.aap.interventionPiObligatoire,
      },
      notationInstruction: {
        isActive: this.aap.instructionPresent,
        typeNote: this.aap.notationInstructionTypeNote,
      },
      kpis: this.aap.kpis,
      organismes: this.aap.organismes,
      poles: {
        polePresent: this.aap.polePresent,
        poleObligatoire: this.aap.poleObligatoire,
        poles: this.aap.poles,
      },
      categories: {
        secteursPresent: this.aap.secteursPresent,
        secteursObligatoire: this.aap.secteursObligatoire,
        secteurs: this.aap.secteurs,
        domainePresent: this.aap.domainePresent,
        domaineObligatoire: this.aap.domaineObligatoire,
        domaines: this.aap.domaines,
        thematiquesPresent: this.aap.thematiquesPresent,
        thematiquesObligatoire: this.aap.thematiquesObligatoire,
        thematiques: this.aap.thematiques,
      },
      documentation: {
        gedConfiguree: this.aap.gedConfiguree,
      },
      demat: {
        depensesProjetPresent: this.aap.depensesProjetPresent,
        ficheComPresent: this.setAapPropertyNullToFalse(this.aap.ficheComPresent),
        ficheDemandeAidePresent: {
          active: this.setAapPropertyNullToFalse(this.aap.ficheDemandeAidePresent?.active),
          etape: this.aap.ficheDemandeAidePresent?.etape,
        },
      },
    });
  }

  setAapPropertyNullToFalse(aapProperty: boolean): boolean {
    if (aapProperty == null) {
      return false;
    } else {
      return aapProperty;
    }
  }

  setAapPropertyNullToTrue(aapProperty: boolean): boolean {
    if (aapProperty == null) {
      return true;
    } else {
      return aapProperty;
    }
  }

  public scrolltoAnchor(elementId: string): void {
    const element = document.getElementById(elementId);
    this.document.getElementById('aap-creation').removeEventListener('scroll', this.scrolling, true);

    setTimeout(() => {
      this.document.getElementById('aap-creation').addEventListener('scroll', this.scrolling, true);
    }, 100);

    element.scrollIntoView({ behavior: 'smooth' });
  }

  public scrolling = ($event: any) => {
    if ($event.target.id !== 'aap-creation') return false;
    const scrollPosition = Math.round($event.target.scrollTop);

    const anchors = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
      i => this.document.getElementById(`anchor${i}`)?.offsetTop - this.headerHeight
    );

    const positionBottom = scrollPosition + this.document.documentElement.offsetHeight - this.headerHeight;
    const maxHeightBottom = this.document.getElementById('aap-creation').scrollHeight;

    if (positionBottom < maxHeightBottom) {
      for (let i = anchors.length - 1; i >= 0; i--) {
        if (scrollPosition >= anchors[i] && (i === anchors.length - 1 || scrollPosition < anchors[i + 1])) {
          this.activeAnchor = `anchor${i + 1}`;
          break;
        }
      }
    } else {
      this.activeAnchor = 'anchor' + anchors.length;
    }
  };

  public saveAap(formResult: any): void {
    const activeButton = document.activeElement.id;
    this.aap.statut = activeButton === 'submit-1' ? EnumAapStatut.BROUILLON : EnumAapStatut.CREE;

    this.pxlPoles.checkPoles();
    this.pxlCategories.checkSecteurs();
    this.pxlCategories.checkDomaines();

    formResult.poles.poles = this.aapForm.value.poles.poles;
    formResult.categories.secteurs = this.aapForm.value.categories.secteurs;
    formResult.categories.domaines = this.aapForm.value.categories.domaines;
    formResult.categories.thematiques = this.pxlCategories.thematiques;

    Object.assign(this.aap, formResult.informations, formResult.poles, formResult.categories, formResult.demat, formResult.documentation);

    if (this.aap.codeComptable === 'Code inconnu') {
      this.aap.codeComptable = null;
    }

    if (formResult.periodicity.heureOuverture) {
      const openTime = formResult.periodicity.heureOuverture.split(':');
      formResult.periodicity.dateOuverture.setHours(openTime[0], openTime[1], 0);
    }

    if (formResult.periodicity.heureFermeture) {
      const closeTime = formResult.periodicity.heureFermeture.split(':');
      formResult.periodicity.dateFermeture.setHours(closeTime[0], closeTime[1], 0);
    }

    Object.assign(this.aap, formResult.periodicity);

    this.aap.partenaire = formResult.partenaire;

    this.aap.kpis = formResult.kpis.map((kpi: Kpi) => new KpiAap(kpi.clef, kpi.id, kpi.categorie.scope));

    if (!this.checkCriteresReferentielOrdre(formResult.criteresEvaluations)) {
      this.showToastrService.error("Deux critères d'évaluation ne peuvent avoir le même ordre d'affichage.");
      return;
    }
    if (!this.checkCriteresReferentielOrdre(formResult.indicateursEligibilites)) {
      this.showToastrService.error("Deux indicateurs d'éligibilité ne peuvent avoir le même ordre d'affichage.");
      return;
    }
    this.aap.criteresEvaluations = formResult.criteresEvaluations.filter((critere: CritereReferentielAap) => critere.isChecked);
    this.aap.criteresEvaluations.forEach(critere => delete critere.isChecked);
    this.aap.indicateursEligibilites = formResult.indicateursEligibilites.filter((critere: CritereReferentielAap) => critere.isChecked);
    this.aap.indicateursEligibilites.forEach(critere => delete critere.isChecked);
    this.aap.organismes = formResult.organismes.map(
      (organisme: OrganismeAap) =>
        new OrganismeAap(
          organisme.idOrganisme,
          organisme.codeThematiques,
          organisme.departements,
          organisme.premiereEtapeProjetAccessible,
          organisme.premierStatutProjetAccessible,
          organisme.permissions,
          organisme.habilitations,
          organisme.nom
        )
    );

    if (!this.featureFlagService.featureOff(EnumFeatureFlipping.AAP_CONFIGURATION_DOCUMENT)) {
      this.aap.documentAaps = DocumentationHelperService.getDocumentsAapDTO(
        this.aapForm.get('documentation').get('documentAaps') as FormArray
      );
      this.aapForm.get('documentation')?.get('gedConfiguree');
    }
    this.updatePhasesForm(formResult);

    this.aapForm.markAsPristine();
    this.aapForm.markAllAsTouched();
    const request = this.aap.id ? this.aapService.updateAap(this.aap) : this.aapService.createAap(this.aap);

    request.subscribe({
      next: (response: HttpResponse<Aap>) => {
        this.showToastrService.success(this.aap.id ? "L'appel à projets a bien été modifié" : 'Votre AAP a été enregistré');
        this.aap = response.body;
        if (activeButton !== 'submit-1') {
          this.router.navigate(['aaps']);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  private updatePhasesForm(formResult: any) {
    this.aap.notationEvaluationTypeNote = formResult.notationEvaluation.typeNote;
    this.aap.notationEvaluationTemplate = formResult.notationEvaluation.template;
    this.aap.notationEvaluationPresent = formResult.notationEvaluation.isActive;
    this.aap.notationBarreEvaluation = formResult.notationEvaluation.notationBarre;

    this.aap.notationAuditionTypeNote = formResult.notationAudition.typeNote;
    this.aap.notationAuditionTemplate = formResult.notationAudition.template;
    this.aap.notationAuditionPresent = formResult.notationAudition.isActive;
    this.aap.notationBarreAudition = formResult.notationAudition.notationBarre;

    this.aap.eligibilitePresent = formResult.notationEligibilite.isActive;
    this.aap.interventionPiObligatoire = formResult.notationProprieteIntellectuelle.isActive;
    this.aap.contractualisationPresent = formResult.notationContractualisation.isActive;

    this.aap.instructionPresent = formResult.notationInstruction.isActive;
    this.aap.notationInstructionTypeNote = formResult.notationInstruction.typeNote;
  }

  checkCriteresReferentielOrdre(criteres: CritereReferentielAap[]): boolean {
    if (criteres == null) {
      return true;
    }
    const criteresFiltered = criteres.filter(critere => critere.ordre != null);
    const uniqueValues = new Set(criteresFiltered.map(critere => critere.ordre));
    return uniqueValues.size === criteresFiltered.length;
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'nav-collapsed');
    window.removeEventListener('scroll', this.scrolling, true);
  }

  getIndex(sectionName: string) {
    return this.steps.findIndex(step => step.section === sectionName) + 1;
  }

  protected readonly EnumFeatureFlipping = EnumFeatureFlipping;
}

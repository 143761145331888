import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AapListComponent } from './aap.list.component';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    SharedUiModule,
    MaterialModule,
    MatCheckboxModule,
    NgbPopoverModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
  declarations: [AapListComponent],
  exports: [AapListComponent],
  providers: [],
})
export class AapListModule {}

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  Domaine,
  DomaineJson,
  DomaineJsonInterface,
  DsMultiselectComponent,
  FilterDataInterface,
  OrganismeAap,
  Secteur,
  SecteurJson,
  SecteurJsonInterface,
  SousDomaine,
  SousSecteur,
  SubscriptionDestroyerComponent,
  Thematique,
} from '@shared-ui';
import { v4 as uuidv4 } from 'uuid';
import { DomainesSharedService } from './domaines-shared.service';
import { SecteursSharedService } from './secteurs-shared.service';

@Component({
  selector: 'pxl-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent extends SubscriptionDestroyerComponent implements OnInit {
  @ViewChild('secteursComponent') secteursComponent: DsMultiselectComponent;
  @ViewChild('domainesComponent') domainesComponent: DsMultiselectComponent;
  @Input() categoriesForm: UntypedFormGroup;
  @Input() organismeFormGroup: UntypedFormGroup;
  @Input() stepNumber: number;

  secteurs: SecteurJson[];
  domaines: DomaineJson[];
  displaySecteursInput = false;
  displaySecteursObligatoireInput = false;
  displayDomainesInput = false;
  displayDomainesObligatoireInput = false;
  displayThematiquesInput = false;
  displayThematiquesObligatoireInput = false;
  displayAssociationThematiques = false;
  dataListSecteurs: FilterDataInterface[] = [];
  dataListDomaines: FilterDataInterface[] = [];
  organismesThematiques: OrganismeAap[] = [];
  organismesThematiquesFormGroup: FormGroup;

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  thematiques: Thematique[] = [];

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public domainesSharedService: DomainesSharedService,
    public secteursSharedService: SecteursSharedService
  ) {
    super();
  }

  ngOnInit(): void {
    this.secteursSharedService
      .getSecteursData()
      .pipe(this.takeUntilDestroyed())
      .subscribe((secteurs: SecteurJson[]) => {
        this.secteurs = secteurs;
        this.secteurs.forEach((secteurs: SecteurJson) => {
          this.dataListSecteurs.push({
            id: secteurs,
            value: secteurs.libelleSecteur + ' - ' + secteurs.libelleSousSecteur,
            isSelected: false,
          });
        });
        this.selectDataListSecteursValues();

        this.setListeSecteursInput(this.categoriesForm.controls.secteursPresent.value);
        this.categoriesForm.controls.secteursPresent.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(secteursPresent => {
          this.setListeSecteursInput(secteursPresent);
        });

        this.setListeSecteursValidators(this.categoriesForm.controls.secteursObligatoire.value);
        this.categoriesForm.controls.secteursObligatoire.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(secteursObligatoire => {
          this.setListeSecteursValidators(secteursObligatoire);
        });
      });

    this.domainesSharedService
      .getDomainesData()
      .pipe(this.takeUntilDestroyed())
      .subscribe((domaines: DomaineJson[]) => {
        this.domaines = domaines;
        this.domaines.forEach((domaines: DomaineJson) => {
          this.dataListDomaines.push({
            id: domaines,
            value: domaines.libelleDomaine + ' - ' + domaines.libelleSousDomaine,
            isSelected: false,
          });
        });
        this.selectDataListDomainesValues();

        this.setListeDomainesInput(this.categoriesForm.controls.domainePresent.value);
        this.categoriesForm.controls.domainePresent.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(domainePresent => {
          this.setListeDomainesInput(domainePresent);
        });

        this.setListeDomainesValidators(this.categoriesForm.controls.domaineObligatoire.value);
        this.categoriesForm.controls.domaineObligatoire.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(domaineObligatoire => {
          this.setListeDomainesValidators(domaineObligatoire);
        });
      });

    this.setThematiques();
    this.categoriesForm.controls.thematiquesPresent.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(thematiquesPresent => {
      this.setListeThematiquesDisabled(thematiquesPresent);
      this.displayAssociationThematiques = this.organismesThematiques.length > 0 && this.categoriesForm?.get('thematiquesPresent')?.value;
    });

    this.categoriesForm.controls.thematiquesObligatoire.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(thematiquesObligatoire => {
      this.setListeThematiquesValidators(thematiquesObligatoire);
    });

    this.organismeFormGroup?.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((organismes: OrganismeAap[]) => {
      this.organismesThematiques = organismes?.filter(organisme => !organisme?.departements || organisme.departements.length === 0) ?? [];
      this.organismesThematiquesFormGroup = new FormGroup({});
      this.organismesThematiques.forEach((organisme: OrganismeAap) => {
        this.organismesThematiquesFormGroup.addControl(organisme.idOrganisme, new FormControl(organisme.codeThematiques));
      });
      this.displayAssociationThematiques = this.organismesThematiques.length > 0 && this.categoriesForm?.get('thematiquesPresent')?.value;
    });
  }

  public setListeSecteursInput(secteursPresent: string): void {
    this.displaySecteursInput = !!secteursPresent;
    if (this.secteursComponent) {
      this.secteursComponent.selectOptions.isDisabled = !secteursPresent;
      this.secteursComponent.reset();
    }
    if (!secteursPresent) {
      this.categoriesForm.patchValue({ secteursObligatoire: false });
    }
  }

  public setListeSecteursValidators(secteursObligatoire: string): void {
    if (secteursObligatoire) {
      this.displaySecteursObligatoireInput = true;
      this.categoriesForm.controls.secteurs.setValidators(Validators.required);
      this.categoriesForm.controls.secteurs.updateValueAndValidity();
    } else {
      this.displaySecteursObligatoireInput = false;
      this.categoriesForm.controls.secteurs.clearValidators();
      this.categoriesForm.controls.secteurs.updateValueAndValidity();
    }
  }

  public setListeDomainesInput(domainesPresent: string): void {
    this.displayDomainesInput = !!domainesPresent;
    if (this.domainesComponent) {
      this.domainesComponent.selectOptions.isDisabled = !domainesPresent;
      this.domainesComponent.reset();
    }
    if (!domainesPresent) {
      this.categoriesForm.patchValue({ domaineObligatoire: false });
    }
  }

  public setListeThematiquesValidators(ThematiquesObligatoire: string): void {
    if (ThematiquesObligatoire) {
      this.displayThematiquesObligatoireInput = true;
      this.categoriesForm.controls.thematiques.setValidators(Validators.required);
      this.categoriesForm.controls.thematiques.updateValueAndValidity();
    } else {
      this.displayThematiquesObligatoireInput = false;
      this.categoriesForm.controls.thematiques.clearValidators();
      this.categoriesForm.controls.thematiques.updateValueAndValidity();
    }
  }

  public setListeThematiquesDisabled(thematiquesPresent: string): void {
    if (thematiquesPresent) {
      this.displayThematiquesInput = true;
      this.categoriesForm.controls.thematiques.reset();
    } else {
      this.displayThematiquesInput = false;
      this.categoriesForm.patchValue({ thematiquesObligatoire: false });
      this.categoriesForm.controls.thematiques.reset();
    }
  }

  public setListeDomainesValidators(domaineObligatoire: string): void {
    if (domaineObligatoire) {
      this.displayDomainesObligatoireInput = true;
      this.categoriesForm.controls.domaines.setValidators(Validators.required);
      this.categoriesForm.controls.domaines.updateValueAndValidity();
    } else {
      this.displayDomainesObligatoireInput = false;
      this.categoriesForm.controls.domaines.clearValidators();
      this.categoriesForm.controls.domaines.updateValueAndValidity();
    }
  }

  checkSecteurs() {
    const secteur: Secteur[] = [];
    this.secteursComponent.getSelectedValues().forEach((secteurs: any) => {
      secteur.push({
        codeSecteur: String(secteurs.id.codeSecteur),
        libelleSecteur: secteurs.id.libelleSecteur,
        sousSecteurs: [
          {
            codeSousSecteur: String(secteurs.id.codeSousSecteur),
            libelleSousSecteur: secteurs.id.libelleSousSecteur,
            descriptionSousSecteur: secteurs.id.descriptionSousSecteur,
          },
        ],
      });
    });

    const result = Object.values(
      secteur.reduce((c: any, { codeSecteur, libelleSecteur, sousSecteurs }) => {
        c[codeSecteur] = c[codeSecteur] || { codeSecteur, libelleSecteur, sousSecteurs: [] };
        c[codeSecteur].sousSecteurs = c[codeSecteur].sousSecteurs.concat(Array.isArray(sousSecteurs) ? sousSecteurs : [sousSecteurs]);
        return c;
      }, {})
    );

    this.categoriesForm.patchValue({ secteurs: result });
  }

  selectDataListSecteursValues() {
    const select: SecteurJsonInterface[] = [];

    if (this.categoriesForm.value.secteurs) {
      this.categoriesForm.value.secteurs.forEach((s: Secteur) => {
        s.sousSecteurs?.forEach((ss: SousSecteur) => {
          select.push({
            codeSecteur: Number(s.codeSecteur),
            libelleSecteur: s.libelleSecteur,
            codeSousSecteur: Number(ss.codeSousSecteur),
            libelleSousSecteur: ss.libelleSousSecteur,
            descriptionSousSecteur: ss.descriptionSousSecteur,
          });
        });
      });

      this.dataListSecteurs.forEach((s: FilterDataInterface) => {
        const obj: any = s.id;
        select.forEach((se: SecteurJsonInterface) => {
          if (obj['codeSecteur'] === se.codeSecteur && obj['codeSousSecteur'] === se.codeSousSecteur) {
            s.isSelected = true;
          }
        });
      });
    }
  }

  checkDomaines() {
    const domaine: Domaine[] = [];
    this.domainesComponent.getSelectedValues().forEach((domaines: any) => {
      domaine.push({
        codeDomaine: String(domaines.id.codeDomaine),
        libelleDomaine: domaines.id.libelleDomaine,
        sousDomaines: [
          {
            codeSousDomaine: String(domaines.id.codeSousDomaine),
            libelleSousDomaine: domaines.id.libelleSousDomaine,
            descriptionSousDomaine: domaines.id.descriptionSousDomaine,
          },
        ],
      });
    });

    // recontruction de l'objet à envoyé côté back
    const result = Object.values(
      domaine.reduce((c: any, { codeDomaine, libelleDomaine, sousDomaines }) => {
        c[codeDomaine] = c[codeDomaine] || { codeDomaine, libelleDomaine, sousDomaines: [] };
        c[codeDomaine].sousDomaines = c[codeDomaine].sousDomaines.concat(Array.isArray(sousDomaines) ? sousDomaines : [sousDomaines]);
        return c;
      }, {})
    );

    this.categoriesForm.patchValue({ domaines: result });
  }

  selectDataListDomainesValues(): void {
    const select: DomaineJsonInterface[] = [];

    if (this.categoriesForm.value.domaines) {
      this.categoriesForm.value.domaines.forEach((d: Domaine) => {
        d.sousDomaines.forEach((sd: SousDomaine) => {
          select.push({
            codeDomaine: Number(d.codeDomaine),
            libelleDomaine: d.libelleDomaine,
            codeSousDomaine: Number(sd.codeSousDomaine),
            libelleSousDomaine: sd.libelleSousDomaine,
            descriptionSousDomaine: sd.descriptionSousDomaine,
          });
        });
      });

      // recontruction de l'objet à envoyé côté back
      this.dataListDomaines.forEach((d: FilterDataInterface) => {
        const obj: any = d.id;
        select.forEach((s: DomaineJsonInterface) => {
          if (obj['codeDomaine'] === s.codeDomaine && obj['codeSousDomaine'] === s.codeSousDomaine) {
            d.isSelected = true;
          }
        });
      });
    }
  }

  setThematiques() {
    if (this.categoriesForm.value.thematiques) {
      this.thematiques = this.categoriesForm.value.thematiques;
    }
  }

  add(event: MatChipInputEvent): void {
    const uuid = uuidv4();
    const value = (event.value || '').trim();

    if ((value || '').trim()) {
      if (!this.thematiques.find(f => f.libelleThematique.toLowerCase() === value.trim().toLowerCase())) {
        this.thematiques.push({ libelleThematique: value.trim(), codeThematique: uuid });
      }
    }

    this.categoriesForm.controls.thematiques.updateValueAndValidity();
    // Clear the input value
    event.chipInput.clear();
  }

  remove(thematique: Thematique): void {
    const index = this.thematiques.indexOf(thematique);

    if (index >= 0) {
      this.thematiques.splice(index, 1);
    }
    this.categoriesForm.controls.thematiques.updateValueAndValidity();
  }

  changeThematique(codeThematiques: string[], id: string) {
    const organismeIndex = this.organismeFormGroup.value.findIndex((organisme: OrganismeAap) => organisme.idOrganisme === id);
    if (organismeIndex !== -1) {
      this.organismeFormGroup.value[organismeIndex].codeThematiques = codeThematiques;
    }
  }
}

import { RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { ProgramListComponent } from '@features-evaluateur/program-list/program.list.component';
import { ProgramCreationComponent } from '@features-evaluateur/program-creation/program.creation.component';
import { AapListComponent } from '@features-evaluateur/aap-list/aap.list.component';
import { AapCreationComponent } from '@features-evaluateur/aap-creation/aap.creation.component';
import { ProjetListComponent } from '@features-evaluateur/projet-list/projet.list.component';
import { CanDeactivateGuard } from './guards/can-deactivate-guard';
import { AuthenticationGuard } from './core/authentification.guard';
import { AutoLoginComponent } from './core/auth-login.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AdminGroupGuard } from './core/admin-group.guard';
import { ProjetListTransverseAapsComponent } from '@features-evaluateur/projet-list-transverse-aaps/projet-list-transverse-aaps.component';
import { DownloadArchiveComponent } from '@features-evaluateur/download-archive/download-archive.component';
import { ProjetParcoursComponent } from '@features-evaluateur/projet-parcours/projet-parcours.component';
import { ProjetValidationDemandeInscriptionComponent } from '@features-evaluateur/projet-info/component/projet-validation-demande-inscription/projet-validation-demande-inscription.component';
import { ConfirmationAccesEvaluateurComponent } from '@features-evaluateur/acces-evaluateur/confirmation-acces-evaluateur.component';
import { RenewAccessEvalComponent } from '@features-evaluateur/renew-access-evaluateur/renew-access-evaluateur.component';
import { AppModule } from './app.module';
import { FeatureFlippingGuard } from '@core-evaluateur/feature-flipping.guard';
import { EnumFeatureFlipping, RechercheTransverseComponent } from '@shared-ui';
import { ProfilGuard } from '@core-evaluateur/profil.guard';
import { EvaluateurChoiceComponent } from '@features-evaluateur/acces-evaluateur/evaluateur-choice/evaluateur-choice.component';
import { EvaluateurGuard } from '@core-evaluateur/evaluateur.guard';

export const AppRoutes: ModuleWithProviders<AppModule> = RouterModule.forRoot([
  {
    path: '',
    redirectTo: 'aaps',
    pathMatch: 'full',
  },
  {
    path: 'autologin',
    component: AutoLoginComponent,
  },
  {
    path: 'programmes',
    component: ProgramListComponent,
    canActivate: [AuthenticationGuard, EvaluateurGuard],
  },
  {
    path: 'comites',
    loadChildren: async () => (await import('./features/comites/comites.module')).ComitesModule,
    canActivate: [AuthenticationGuard, AdminGroupGuard, FeatureFlippingGuard],
    data: { featureName: EnumFeatureFlipping.COMITES },
  },
  {
    path: 'referentiels',
    loadChildren: async () => (await import('./features/referentiels/referentiels.module')).ReferentielsModule,
    canActivate: [AuthenticationGuard, AdminGroupGuard, FeatureFlippingGuard],
    data: { featureName: EnumFeatureFlipping.REFERENTIELS },
  },
  {
    path: 'creationProgramme',
    component: ProgramCreationComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthenticationGuard, NgxPermissionsGuard, EvaluateurGuard],
    data: {
      permissions: {
        only: 'PARAMETRAGE_WRITE',
        redirectTo: 'programmes',
      },
    },
  },
  {
    path: 'modifierProgramme/:id',
    component: ProgramCreationComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthenticationGuard, NgxPermissionsGuard, EvaluateurGuard],
    data: {
      permissions: {
        only: 'PARAMETRAGE_WRITE',
        redirectTo: 'programmes',
      },
    },
  },
  {
    path: 'archive/:id',
    component: DownloadArchiveComponent,
    canActivate: [AuthenticationGuard, EvaluateurGuard],
  },
  {
    path: 'aaps',
    component: AapListComponent,
    canActivate: [AuthenticationGuard, ProfilGuard],
    data: {
      resetReuseStrategy: true,
    },
  },
  {
    path: 'creationAap',
    component: AapCreationComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthenticationGuard, NgxPermissionsGuard, EvaluateurGuard],
    data: {
      permissions: {
        only: 'PARAMETRAGE_WRITE',
        redirectTo: 'aaps',
      },
    },
  },
  {
    path: 'modifierAap/:id',
    component: AapCreationComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthenticationGuard, NgxPermissionsGuard, EvaluateurGuard],
    data: {
      permissions: {
        only: 'PARAMETRAGE_WRITE',
        redirectTo: 'aaps',
      },
    },
  },
  {
    path: 'aaps/:id/projets',
    component: ProjetListComponent,
    canActivate: [AuthenticationGuard, EvaluateurGuard],
  },
  {
    path: 'aaps-transverse/projets',
    component: ProjetListTransverseAapsComponent,
    canActivate: [AuthenticationGuard, EvaluateurGuard],
  },
  {
    path: 'valider-demande-inscription/:demandeInscriptionId',
    component: ProjetValidationDemandeInscriptionComponent,
    canActivate: [AuthenticationGuard, EvaluateurGuard],
  },
  {
    path: 'projets/:projetId',
    loadChildren: async () => (await import('./features/projet-info/projet-info.module')).ProjetInfoModule,
    canActivate: [AuthenticationGuard, EvaluateurGuard],
    data: {
      permissions: {
        only: 'PROJET_READ',
        redirectTo: 'aaps',
      },
    },
  },
  {
    path: 'aaps/:id/projet-parcours',
    component: ProjetParcoursComponent,
    canActivate: [AuthenticationGuard],
    data: {
      permissions: {
        only: 'PROJET_WRITE',
        redirectTo: 'aaps',
      },
    },
  },
  {
    path: 'confirmation-access-evaluateur',
    component: ConfirmationAccesEvaluateurComponent,
    canActivate: [AuthenticationGuard],
    data: {
      showNavBar: false,
    },
  },
  {
    path: 'valider-demande-reactivation-compte/:invitation_id',
    component: RenewAccessEvalComponent,
    canActivate: [AuthenticationGuard],
    data: {
      showNavBar: false,
    },
  },
  {
    path: 'feature-flipping',
    loadChildren: () => import('./features/feature-flipping/feature-flipping.module').then(m => m.FeatureFlippingModule),
    canActivate: [AuthenticationGuard, AdminGroupGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'FEATURE_FLIPPING',
        redirectTo: 'aaps',
      },
    },
  },
  {
    path: 'evaluateur-choice',
    component: EvaluateurChoiceComponent,
    canActivate: [AuthenticationGuard],
    data: {
      showNavBar: false,
    },
  },
  {
    path: 'recherche-transverse',
    component: RechercheTransverseComponent,
    canActivate: [AuthenticationGuard, ProfilGuard],
    data: {
      restrictedTableHeader: true,
    },
  },
]);

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { CustomToastModule } from '@shared-evaluateur/components/toastr/custom-toastr.module';
import { ModificationDatesModalComponent } from './modification-dates-modal.component';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [ModificationDatesModalComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, SharedModule, CustomToastModule, SharedUiModule],
})
export class ModificationDatesModalModule {}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProjetService } from '@services-evaluateur/projet.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import {
  Aap,
  EnumProjetStatut,
  EnumRoleContact,
  EnumRoleStructure,
  EnumTypePartenaire,
  EnumTypeWorkflow,
  Projet,
  ShowToastrService,
  siretValidator,
  Structure,
} from '@shared-ui';
import { Observable } from 'rxjs';

declare const dsLib: any;

@Component({
  selector: 'pxl-parcours-creation',
  templateUrl: './parcours-creation.component.html',
  styleUrls: ['./parcours-creation.component.scss'],
})
export class ParcoursCreationComponent implements OnInit {
  parcoursForm: UntypedFormGroup;
  projet: Projet = new Projet();
  contacts: UntypedFormArray;
  structure: Structure = new Structure();
  page = 1;
  telephone = {};

  @Input() aap: Aap;
  @Input() isAapMultiMandatory = true;
  @Input() isAapIndivMandatory = false;
  @Input() isAapPersonnePhysique = false;
  @Output() atPage = new EventEmitter<any>();
  @Output() structureEnCreationChange = new EventEmitter<boolean>();
  structureEnCreation = false;
  structureIdentifiedByRET = false; // found in RET or not

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private projetService: ProjetService,
    private utilisateurService: UtilisateurService,
    private showToastrService: ShowToastrService
  ) {}

  ngOnInit(): void {
    this.createForms();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.parcoursForm.dirty;
  }

  createForms(): void {
    this.parcoursForm = this.formBuilder.group({
      role: [null, Validators.required],
      partenaire: this.formBuilder.group({
        partenaireType: [null, Validators.required],
      }),

      infoStructure: this.formBuilder.group({
        typeStructure: [null, Validators.required],
        siret: [null, [Validators.required, siretValidator()]],
        raisonSiret: [null, Validators.required],
        raisonSocial: [null, Validators.required],
        activite: [null, Validators.required],
        organisationPId: [null],
        numeroCP: [null],
        adresse: this.formBuilder.group({
          codePays: [null, Validators.required],
          pays: [null, Validators.required],
          cp: [null, Validators.required],
          ville: [null, Validators.required],
          mentionDistribution: [null],
          hameau: [null],
          numero: [null, Validators.required],
          complement: [null],
          typeVoie: [null, Validators.required],
          voie: [null, Validators.required],
          complementInfo: [null],
          divisionAdministrative: [null],
        }),
      }),

      contacts: this.formBuilder.array([
        this.formBuilder.group({
          genre: [null, Validators.required],
          nom: [null, Validators.required],
          prenom: [null, Validators.required],
          roles: [[(EnumRoleContact as any)[EnumRoleContact.RESPONSABLE_PROJET.toString()]], Validators.required],
          matricule: [null],
          telephone: [null, Validators.required],
          email: [null, Validators.required],
        }),
      ]),
    });
  }

  submitForm(): void {
    if (this.parcoursForm.valid) {
      const parcoursValue = this.parcoursForm.getRawValue();

      this.projet.aap = this.aap;
      this.projet.etapes = [
        {
          nom: EnumTypeWorkflow.getFirstEtape(this.projet.aap.typeWKF),
          statut: EnumProjetStatut.EN_COURS,
          date: new Date(),
        },
      ];
      this.projet.statut = EnumProjetStatut.EN_COURS;

      Object.assign(this.structure, parcoursValue.infoStructure);
      this.structure.role = parcoursValue.role;
      this.structure.contacts = parcoursValue.contacts;
      this.structure.informationsReferentiel = {
        numeroCP: parcoursValue.infoStructure.numeroCP,
        organisationPId: parcoursValue.infoStructure.organisationPId,
        organisationCreationDate: parcoursValue.infoStructure?.organisationCreationDate,
      };

      if (this.structureEnCreation) {
        this.structure.contacts[0]['adresse'] = parcoursValue.infoStructure.adresse;
        this.structure.adresse = null;
      }

      if (this.structure.role === EnumRoleStructure.MANDATAIRE) {
        this.structure.contacts[0].roles = [];
      }

      this.projet.partenaireType = this.parcoursForm.value.partenaire.partenaireType;

      this.projet.nouvelleStructure = this.structure;

      this.projetService.postProjet(this.projet).subscribe({
        next: (responseProjet: any) => {
          if (responseProjet) {
            this.showToastrService.success('Nouveau projet créé');
            this.utilisateurService.getUtilisateur().subscribe();
            this.onGoToPageApercu(responseProjet.body.id);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    } else {
      this.parcoursForm.markAllAsTouched();
    }
  }

  buildInfoStructureForm(): FormGroup {
    const infoStructForm = this.parcoursForm.get('infoStructure') as FormGroup;
    if (this.parcoursForm.get('role').value === EnumRoleStructure.CHEF_DE_FILE) {
      infoStructForm.addControl('effectifSiege', this.formBuilder.control('', Validators.required));
      infoStructForm.addControl('effectifGroupe', this.formBuilder.control('', Validators.required));
    } else {
      infoStructForm.removeControl('effectifSiege');
      infoStructForm.removeControl('effectifGroupe');
    }
    return infoStructForm;
  }

  changePage(switchPage: boolean): void {
    if (switchPage) {
      this.checkPageAapMandatory();
      this.handlePagesValidation();
    } else {
      this.page--;
      if (this.page === 0) {
        this.router.navigate(['aaps/' + this.aap.id + '/projets']);
      }
      this.parcoursForm.markAsUntouched();
    }
    this.atPage.emit(this.page);
  }

  checkPageAapMandatory(): void {
    if (this.page === 1 && this.isAapMultiMandatory) {
      this.parcoursForm.controls.partenaire.patchValue({
        partenaireType: EnumTypePartenaire.MULTI,
      });
    }
    if (this.page === 1 && this.isAapIndivMandatory) {
      this.parcoursForm.controls.partenaire.patchValue({
        partenaireType: EnumTypePartenaire.INDIV,
      });
    }
    if (
      (this.page === 1 && (this.isAapMultiMandatory || this.isAapIndivMandatory)) ||
      (this.page === 2 && !this.isAapMultiMandatory && !this.isAapIndivMandatory)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.telephone = new dsLib.InputPhone('telephone');
    }
  }

  handlePagePartenaire(): boolean {
    const isChoixTypePartenairePage = this.page === 1 && !this.aapIndivOrMultiMandatory();
    if (isChoixTypePartenairePage && !this.parcoursForm.controls.partenaire.valid) {
      this.parcoursForm.controls.partenaire.markAllAsTouched();
      return true;
    }
    return false;
  }

  handlePageRole(): boolean {
    const isChoixRolePage = (this.page === 1 && this.aapIndivOrMultiMandatory()) || (this.page === 2 && !this.aapIndivOrMultiMandatory());
    if (isChoixRolePage && !this.parcoursForm.controls.role.valid) {
      this.parcoursForm.controls.role.markAllAsTouched();
      return true;
    }
    return false;
  }

  handlePageInfoStructure(): boolean {
    const isInfoStructurePage =
      (this.page === 3 && this.aapIndivOrMultiMandatory()) || (this.page === 4 && !this.aapIndivOrMultiMandatory());
    if (isInfoStructurePage && !this.parcoursForm.controls.infoStructure.valid) {
      this.parcoursForm.controls.infoStructure.markAllAsTouched();
      return true;
    }
    return false;
  }

  handlePagesValidation(): void {
    if (this.handlePagePartenaire()) {
      return;
    }
    if (this.handlePageRole()) {
      return;
    }
    if (this.handlePageInfoStructure()) {
      return;
    }
    this.page++;
    this.parcoursForm.markAsUntouched();
  }

  onGoToPageApercu(projetId: string): void {
    this.router.navigate(['projets', projetId, 'projet-apercu']);
  }

  onStructureEnCreationChange(event: boolean) {
    this.structureEnCreationChange.emit(event);
    this.structureEnCreation = event;
  }

  private aapIndivOrMultiMandatory() {
    return this.isAapMultiMandatory || this.isAapIndivMandatory;
  }
}

<pxl-sidenav-creation-aap [steps]="steps" [activeAnchor]="activeAnchor" (anchorCall)="scrolltoAnchor($event)"></pxl-sidenav-creation-aap>

<main id="aap-creation" class="page-content">
  <form [formGroup]="aapForm" class="form">
    <section class="form-step-wrapper">
      <generals-informations
        *ngIf="showForm"
        [informationsForm]="aapForm.get('informations')"
        [projetLength]="projetLength"
        id="anchor1"
        [stepNumber]="getIndex('anchor1')"
      ></generals-informations>
    </section>

    <section class="form-step-wrapper">
      <periodicity-and-dates
        [stepNumber]="getIndex('anchor2')"
        [periodicityForm]="aapForm.get('periodicity')"
        id="anchor2"
      ></periodicity-and-dates>
    </section>

    <section class="form-step-wrapper">
      <project-structure [stepNumber]="getIndex('anchor3')" [partenaireForm]="aapForm.get('partenaire')" id="anchor3"></project-structure>
    </section>

    <section class="form-step-wrapper" [libMyRemoveIfFeatureOff]="'grille-impacts'">
      <pxl-grille-impact
        *ngIf="aap.id || !isEditingExistingAap"
        [kpiFormGroup]="aapForm.get('kpis')"
        [aap]="aap"
        id="anchor4"
        [stepNumber]="getIndex('anchor4')"
      ></pxl-grille-impact>
    </section>

    <section class="form-step-wrapper" [libMyRemoveIfFeatureOff]="'criteres-evaluation'">
      <pxl-criteres-referentiel
        [criteres]="aap.criteresEvaluations"
        [aapForm]="aapForm"
        [isNotation]="true"
        controlName="criteresEvaluations"
        *ngIf="aap.id || !isEditingExistingAap"
        id="anchor5"
        [stepNumber]="getIndex('anchor5')"
      ></pxl-criteres-referentiel>
    </section>

    <section class="form-step-wrapper" [libMyRemoveIfFeatureOff]="'indicateurs-eligibilite'">
      <pxl-criteres-referentiel
        [criteres]="aap.indicateursEligibilites"
        [aapForm]="aapForm"
        [isNotation]="false"
        controlName="indicateursEligibilites"
        *ngIf="aap.id || !isEditingExistingAap"
        id="anchor6"
        [stepNumber]="getIndex('anchor6')"
      ></pxl-criteres-referentiel>
    </section>

    <section class="form-step-wrapper">
      <pxl-phases [stepNumber]="getIndex('anchor7')" [aapForm]="aapForm" id="anchor7"></pxl-phases>
    </section>

    <section class="form-step-wrapper" [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.ORGANISMES">
      <pxl-organismes [stepNumber]="getIndex('anchor8')" [organismeFormGroup]="aapForm.get('organismes')" id="anchor8"></pxl-organismes>
    </section>

    <section class="form-step-wrapper" *ngIf="aap.id || !isEditingExistingAap">
      <pxl-poles [stepNumber]="getIndex('anchor11')" [polesFormGroup]="aapForm.get('poles')" id="anchor11"></pxl-poles>
    </section>

    <section class="form-step-wrapper" *ngIf="aap.id || !isEditingExistingAap">
      <pxl-categories
        [stepNumber]="getIndex('anchor9')"
        [categoriesForm]="aapForm.get('categories')"
        [organismeFormGroup]="aapForm.get('organismes')"
        id="anchor9"
      ></pxl-categories>
    </section>

    <section class="form-step-wrapper" [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.AAP_CONFIGURATION_DOCUMENT">
      <pxl-documentation
        [stepNumber]="getIndex('anchor10')"
        [documentationFormGroup]="aapForm.get('documentation')"
        [partenaireForm]="aapForm.get('partenaire')"
        [aap]="aap"
        id="anchor10"
      ></pxl-documentation>
    </section>

    <section class="form-step-wrapper">
      <pxl-document-dematerialises
        [stepNumber]="getIndex('anchor12')"
        [documentationDematerialiseForm]="aapForm.get('demat')"
        id="anchor12"
      ></pxl-document-dematerialises>
    </section>

    <div class="form-actions">
      <ng-container *ngxPermissionsOnly="['PARAMETRAGE_WRITE']">
        <button
          *ngIf="!isCreatedAap"
          type="submit"
          id="submit-1"
          value="Submit 1"
          class="ds-btn ds-btn--primary"
          (click)="saveAap(aapForm.value)"
        >
          <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
          Enregistrer
        </button>
      </ng-container>

      <ng-container *ngxPermissionsOnly="['PARAMETRAGE_WRITE']">
        <button
          *ngIf="!isCreatedAap"
          type="submit"
          id="submit-2"
          value="Submit 2"
          class="ds-btn ds-btn--primary"
          [disabled]="!aapForm.valid"
          (click)="saveAap(aapForm.value)"
        >
          <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon>
          Créer l'appel à projet
        </button>
      </ng-container>

      <ng-container *ngxPermissionsOnly="['PARAMETRAGE_WRITE']">
        <button
          *ngIf="isCreatedAap"
          id="creation_aap_btn_update"
          value="Submit 2"
          type="submit"
          class="ds-btn ds-btn--primary"
          [disabled]="!aapForm.valid"
          (click)="saveAap(aapForm.value)"
        >
          <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon>
          Modifier l'appel à projet
        </button>
      </ng-container>
    </div>
  </form>
</main>

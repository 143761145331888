<div class="page-content page-content--no-scroll list-page">
  @if (user) {
    <lib-alertes-publiees class="alertes no-space" [positionnement]="EnumPositionnement.ACCUEIL" [utilisateur]="user"></lib-alertes-publiees>
  }

  <div class="list-page__header">
    <h1 class="list-page__title">Tous les appels à projets</h1>
    <div class="list-page__dropdowns">
      <ng-container *ngIf="showTransverseSearch">
        <button
          title="Recherche projet(s)"
          type="button"
          class="list-page__create-button ds-btn ds-btn--secondary button_search_project"
          (click)="searchProjetsTransverseAaps()"
        >
          <lib-pxl-icon class="icon" icon="icon-other-search"></lib-pxl-icon>
          Recherche projet(s)
        </button>
      </ng-container>
      <ng-container *ngIf="sharedFunction.isEvaluateurInstructeurExterne(evaluateur)">
        <button
          type="button"
          class="list-page__create-button ds-btn ds-btn--secondary"
          (click)="createProg()"
          *ngxPermissionsOnly="['PARAMETRAGE_WRITE']"
        >
          <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
          Nouvel appel à projets
        </button>
      </ng-container>
      <ng-container *ngIf="isDoubleProfile">
        <button id="go-to-choice" class="ds-btn ds-btn--secondary me-2" (click)="onClickGoToChoice()">
          Retour au choix du parcours évaluateur
        </button>
      </ng-container>
      <ng-container>
        <button id="switch-to-evaluateur" class="ds-btn ds-btn--secondary" (click)="redirectToCandidats()">
          Accéder à mon espace candidat
        </button>
      </ng-container>
    </div>
  </div>

  <div id="aap-filter">
    <lib-ds-multiselect
      #AAPTypeComponent
      class="aap-filter"
      [dataList]="dataListAAPType"
      [selectOptions]="{ placeholder: 'AAPs', isDisabled: !displayAAPTypeInput }"
      [name]="'liste_aap_type'"
    >
    </lib-ds-multiselect>
    <div class="search-buttons-container">
      <button type="button" class="ds-btn ds-btn--secondary" (click)="resetAllFilters()">
        <span class="ds-icon ds-icon-left ds-float--left" aria-hidden="true">refresh</span>
        Réinitialiser
      </button>
      <button id="actions-btn" class="ds-btn ds-btn--primary" (click)="submitSearch()">Rechercher</button>
    </div>
  </div>

  <div id="programme-table" class="table">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="dateFermeture" matSortDirection="asc">
      <caption>
        Liste des aaps
      </caption>
      <ng-container matColumnDef="programme.type">
        <th id="aap-program" mat-header-cell *matHeaderCellDef mat-sort-header>Programme</th>
        <td id="type" mat-cell *matCellDef="let element">
          {{ element.programme.type }}
        </td>
      </ng-container>
      <ng-container matColumnDef="codeFront">
        <th id="aap-name" mat-header-cell *matHeaderCellDef mat-sort-header>Nom de l'AAP</th>
        <td id="code" mat-cell *matCellDef="let element">{{ element.code }} {{ element.immersion === true ? '(Immersion)' : '' }}</td>
      </ng-container>
      <ng-container matColumnDef="statut">
        <th id="aap-statut" mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
        <td id="statut" mat-cell *matCellDef="let element">
          <span class="table__status">{{ EnumAapStatut.toString(element.statut) }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="dateOuverture">
        <th id="aap-open-date" mat-header-cell *matHeaderCellDef mat-sort-header>Ouverture</th>
        <td id="date-ouverture" mat-cell *matCellDef="let element">{{ element.dateOuverture | date : 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="dateFermeture">
        <th id="aap-close-date" mat-header-cell *matHeaderCellDef mat-sort-header>Cloture</th>
        <td id="date-fermeture" mat-cell *matCellDef="let element">{{ element.dateFermeture | date : 'dd/MM/yyyy' }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        id="go-projet"
        mat-row
        class="r"
        *matRowDef="let row; columns: displayedColumns"
        (click)="getProjets(row.id)"
        style="cursor: pointer"
      ></tr>
    </table>
    <div class="no-result" *ngIf="dataSource?.data?.length === 0">
      <img src="assets/images/no_projects.svg" alt="Pas de résultats" />
      <span id="large-span-result">Il n'y a rien ici...</span>
      <span id="small-span-result">vous n'êtes affecté actuellement à aucun projet</span>
    </div>
  </div>
</div>

<section class="form-step">
  <div id="anchor11" class="form-step__anchor"></div>
  <header class="form-step__header">
    <h2 class="form-step__title">
      <span class="form-step__number">{{ stepNumber }}</span>
      Pôles
    </h2>
  </header>

  <form id="poles" [formGroup]="polesFormGroup">
    <div class="form-step__section section-poles">
      <!-- Présence de pôles-->
      <div id="input-poles" class="form-step__poles">
        <p class="radio-group-title">Présence de Pôle <span class="ds-input-group__label-asterisk"> *</span></p>
        <div class="ds-radio-group">
          <input
            type="radio"
            name="polePresent"
            id="poles-true"
            class="ds-radio-group__radio-input"
            [value]="true"
            formControlName="polePresent"
          />
          <label for="poles-true" class="ds-radio-group__radio-label">Oui</label>

          <input
            type="radio"
            name="polePresent"
            id="poles-false"
            class="ds-radio-group__radio-input"
            [value]="false"
            formControlName="polePresent"
          />
          <label for="poles-false" class="ds-radio-group__radio-label">Non</label>
        </div>
      </div>
      <!-- FIN de Présence de pôles -->

      <!-- Obligation de Pôle-->
      <div id="input-poles" class="form-step__poles-obligatoire">
        <p class="radio-group-title">Obligation de Pôle <span class="ds-input-group__label-asterisk"> *</span></p>
        <div class="ds-radio-group">
          <input
            type="radio"
            name="poleObligatoire"
            id="poles-obligatoire-true"
            class="ds-radio-group__radio-input"
            [value]="true"
            formControlName="poleObligatoire"
            [attr.disabled]="!polesFormGroup.controls['polePresent'].value === true ? true : null"
          />
          <label
            [ngClass]="{ 'radio-disabled': !polesFormGroup.controls['polePresent'].value === true }"
            for="poles-obligatoire-true"
            class="ds-radio-group__radio-label"
            >Oui</label
          >

          <input
            type="radio"
            name="poleObligatoire"
            id="poles-obligatoire-false"
            class="ds-radio-group__radio-input"
            [value]="false"
            formControlName="poleObligatoire"
            [attr.disabled]="!polesFormGroup.controls['polePresent'].value === true ? true : null"
          />
          <label
            [ngClass]="{ 'radio-disabled': !polesFormGroup.controls['polePresent'].value === true }"
            for="poles-obligatoire-false"
            class="ds-radio-group__radio-label"
            >Non</label
          >
        </div>
      </div>
      <!-- FIN de Obligation de Pôle -->

      <!-- Liste des pôles -->
      <div
        class="form-step__liste-poles ds-input-group"
        [class.ds-input-group--error]="
          displayPolesInput && !polesFormGroup.controls['poles']?.valid && polesFormGroup.controls['poles']?.touched
        "
      >
        <label for="liste_poles" class="ds-input-group__label"
          >Liste des pôles<span class="ds-input-group__label-asterisk" *ngIf="displayPolesInput && displayPolesObligatoireInput"> *</span>
        </label>

        <div
          class="ds-select ds-select-group"
          [class.ds-select--erreur]="
            displayPolesInput && !polesFormGroup.controls['poles']?.valid && polesFormGroup.controls['poles']?.touched
          "
          *ngIf="dataListPoles.length"
        >
          <lib-ds-multiselect
            #poleComponent
            class="filter"
            [dataList]="dataListPoles"
            [selectOptions]="{ placeholder: 'Sélectionner', isDisabled: !displayPolesInput }"
            [name]="'liste_poles'"
            (click)="checkPoles()"
          >
          </lib-ds-multiselect>
        </div>
        <ng-container *ngIf="displayPolesInput && !polesFormGroup.controls['poles']?.valid && polesFormGroup.controls['poles']?.touched">
          <div class="ds-input-group__feedback">Merci de renseigner le/les pôles</div>
        </ng-container>
      </div>
      <!-- FIN de Liste des pôles -->
    </div>
  </form>
</section>

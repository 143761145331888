<div class="search-container">
  <div class="filter-row" id="first-row">
    <lib-custom-multiselect
      #acronymeComponent
      (autocompleteEvent)="autocompleteEvent($event, ACRONYME)"
      (selectedListChange)="selectedListChange($event, ACRONYME)"
      [(dataList)]="acronymesDataList"
      [selectedList]="acronymesSelectedList"
      placeholder="Acronyme projet"
      name="Acronyme"
      class="filter"
      [isLoading]="loadingMap.get(ACRONYME)"
    >
    </lib-custom-multiselect>

    <lib-custom-multiselect
      #rsSiretComponent
      (autocompleteEvent)="autocompleteEvent($event, RS_SIRET)"
      (selectedListChange)="selectedListChange($event, RS_SIRET)"
      [(dataList)]="rsSiretDataList"
      [selectedList]="rsSiretSelectedList"
      placeholder="Raison sociale ou SIREN"
      name="raison sociale ou siren"
      class="filter"
      [isLoading]="loadingMap.get(RS_SIRET)"
    >
    </lib-custom-multiselect>

    <lib-ds-multiselect
      #thematiqueComponent
      class="filter"
      [dataList]="thematiqueDataList"
      [selectOptions]="{ placeholder: 'Thématique' }"
      [name]="'thematique-filter'"
    >
    </lib-ds-multiselect>
  </div>

  <div class="filter-row" id="second-row" [ngClass]="{ 'hidden-row': !showHiddenRows }">
    <lib-ds-multiselect
      #releveComponent
      class="filter"
      [dataList]="releveDataList"
      [selectOptions]="{ placeholder: 'Relève' }"
      [name]="'releve-filter'"
    >
    </lib-ds-multiselect>
    <lib-ds-multiselect
      #etapeComponent
      class="filter"
      [dataList]="etapeDataList"
      [selectOptions]="{ placeholder: 'Etape' }"
      [name]="'etape-filter'"
    >
    </lib-ds-multiselect>
    <lib-ds-multiselect
      #statutComponent
      class="filter"
      [dataList]="statutDataList"
      [selectOptions]="{ placeholder: 'Statut' }"
      [name]="'statut-filter'"
    >
    </lib-ds-multiselect>
  </div>

  <div
    class="filter-row"
    id="third-row"
    [ngClass]="{ 'hidden-row': !showHiddenRows }"
    [libMyRemoveIfFeatureOff]="'filter-taches-a-realiser'"
  >
    <lib-ds-multiselect
      #drdlComponent
      class="filter"
      [matTooltip]="tooltiptext"
      [dataList]="drdlDataList"
      [selectOptions]="{ placeholder: 'DR/DL Bpifrance' }"
      [name]="'drdl-filter'"
    >
    </lib-ds-multiselect>

    <lib-ds-multiselect
      #tachesComponent
      class="filter"
      [dataList]="tacheDataList"
      [selectOptions]="{ placeholder: 'Tâches à réaliser' }"
      [name]="'tache-filter'"
    >
    </lib-ds-multiselect>

    <lib-ds-multiselect
      #comiteComponent
      class="filter"
      [dataList]="comiteDataList"
      [selectOptions]="{ placeholder: 'Comités' }"
      [name]="'comite-filter'"
    >
    </lib-ds-multiselect>

    <lib-ds-multiselect
      #phaseDecisionComponent
      class="filter"
      [dataList]="phaseDecisionDataList"
      [selectOptions]="{ placeholder: 'Type de décision' }"
      [name]="'phase-decision-filter'"
    >
    </lib-ds-multiselect>
  </div>

  <div class="filter-row" id="final-row">
    <button class="ds-btn ds-btn--tertiary" (click)="showHiddenRows = !showHiddenRows">
      {{ showHiddenRows ? 'Moins' : 'Plus' }} de critères
    </button>
    <button type="button" class="ds-btn ds-btn--secondary" (click)="resetAllFilters()">
      <span class="ds-icon ds-icon-left ds-float--left" aria-hidden="true">refresh</span>
      Réinitialiser
    </button>
    <button id="actions-btn" class="ds-btn ds-btn--primary" (click)="submitSearch()">Rechercher</button>
  </div>
</div>

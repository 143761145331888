import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments-evaluateur/environment';
import { EvaluateurService } from '@services-evaluateur/evaluateur.service';
import { ProjetService } from '@services-evaluateur/projet.service';
import { Evaluateur, ShowToastrService } from '@shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pxl-partenaire-renew-access',
  templateUrl: './renew-access-evaluateur.component.html',
})
export class RenewAccessEvalComponent implements OnInit, OnDestroy {
  readonly INCOMPLETE_EVAL = 'INCOMPLETE_EVAL';
  private invitationId: string;

  evaluateur: Evaluateur;

  subscriptions: Subscription[] = [];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public projetService: ProjetService,
    private evaluateurService: EvaluateurService,
    private showToastrService: ShowToastrService
  ) {}

  ngOnInit(): void {
    this.invitationId = this.route.snapshot.params.invitation_id;

    this.subscriptions.push(
      this.evaluateurService.renewAccess(this.invitationId).subscribe({
        next: () => {
          this.showToastrService.success('Votre compte est désormais actif.');
          this.onGoToAapsList();
        },
        error: err => {
          if (err.error?.code === '1332') {
            this.showToastrService.error(err.error.message);
            this.router.navigate(['/confirmation-access-evaluateur']);
          } else {
            window.location.href = `${environment.espaceCandidat}/espace?error=${err.error?.code}`;
          }
        },
      })
    );
  }

  onGoToAapsList(): void {
    window.location.href = window.location.protocol + '//' + window.location.host + '/aaps';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }
}

import { UserWorkflow, Workflow } from '@shared-evaluateur/utils/constants/workflow';
import { Aap, PermissionUtils, Utilisateur } from '@shared-ui';

export function processUserWorkflowOnAap(wkf: Workflow, aap: Aap, user: Utilisateur): UserWorkflow {
  return wkf.map(etape => {
    const displayedSousEtapes = etape.statuts.map(sousEtape => {
      return {
        ...sousEtape,
        accessible: sousEtape.requiredPermission ? PermissionUtils.hasPermissionOnAap(user, sousEtape.requiredPermission, aap) : false,
      };
    });
    return {
      ...etape,
      statuts: displayedSousEtapes,
      accessible: displayedSousEtapes.some(sousEtape => sousEtape.accessible),
    };
  });
}

import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProfilEvaluateur } from '@core-evaluateur/profil.guard';
import { environment } from '@environments-evaluateur/environment';
import { CHOSEN_PROFILE_KEY } from '@features-evaluateur/acces-evaluateur/evaluateur-choice/evaluateur-choice.component';
import { AapService } from '@services-evaluateur/aap.service';
import { EvaluateurService } from '@services-evaluateur/evaluateur.service';
import { PxlOidcService } from '@services-evaluateur/pxl-oidc.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { AffectationIntervenantModalComponent } from '@shared-evaluateur/components/modals/affectation-intervenant-modal/affectation-intervenant-modal.component';
import { CustomToastComponent } from '@shared-evaluateur/components/toastr/custom-toastr';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import {
  Aap,
  DsMultiselectComponent,
  EnumAapStatut,
  EnumPermissionUtilisateur,
  enumValues,
  Evaluateur,
  FilterDataInterface,
  IntervenantRoles,
  PermissionUtils,
  ShowToastrService,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';
import { ActiveToast, Toast, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'pxl-aap-list',
  templateUrl: './aap.list.component.html',
  styleUrls: ['./aap.list.component.scss'],
})
export class AapListComponent extends SubscriptionDestroyerComponent implements OnInit {
  @ViewChild('AAPTypeComponent') aapTypeComponent: DsMultiselectComponent;
  displayedColumns: string[] = ['programme.type', 'codeFront', 'statut', 'dateOuverture', 'dateFermeture'];
  dataSource: MatTableDataSource<Aap>;
  selection = new SelectionModel<Aap>(true, []);
  @ViewChild(MatSort) sort: MatSort;
  evaluateur: Evaluateur;
  user: Utilisateur;
  favoriteAapStates: { [id: string]: { isFavorite: boolean } };
  displayAAPTypeInput = true;
  dataListAAPType: FilterDataInterface[] = [
    { id: '0', value: 'AAPs ouverts', isSelected: false },
    { id: '1', value: 'AAPs fermés', isSelected: false },
    { id: '2', value: 'AAPs brouillons', isSelected: false },
    { id: '3', value: 'AAPs Immersion', isSelected: false },
  ];

  activeToast: ActiveToast<Toast>;

  toggleLabels: string[];
  activeToggleLabel = ToggleValues.TOUS_AAP;

  affecter = 'Affecter superviseur AAP';
  desaffecter = 'Désaffecter superviseur AAP';
  enableAction = false;
  enableDesaffecterSuperviseur = false;
  showTransverseSearch = false;
  isDoubleProfile = localStorage.getItem('DOUBLE_PROFILE') === 'true';

  EnumAapStatut = EnumAapStatut;

  constructor(
    private router: Router,
    private aapService: AapService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public sharedFunction: SharedFunction,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private oidcSecurityService: PxlOidcService,
    private evaluateurService: EvaluateurService,
    public showToastrService: ShowToastrService,
    public utilisateurService: UtilisateurService
  ) {
    super();
    if (this.sharedFunction.isSiteAdmin()) {
      this.displayedColumns = [...this.displayedColumns, 'projets', 'actions', 'favoris'];
      this.toggleLabels = enumValues(ToggleValues);
    }
  }

  ngOnInit(): void {
    localStorage.removeItem('filtersSearchObject');
    localStorage.removeItem('selectedSearchValues');
    this.utilisateurService.getUtilisateurObservable().pipe(this.takeUntilDestroyed()).subscribe(this.onUserUpdate.bind(this));
    this.renderer.addClass(this.document.body, 'nav-collapsed');

    this.oidcSecurityService.isAuthenticated$?.subscribe(({ isAuthenticated }) => {
      if (isAuthenticated) {
        this.evaluateurService
          .getEvaluateurObservable()
          .pipe(this.takeUntilDestroyed())
          .subscribe(response => {
            this.evaluateur = response?.body;
          });
        this.loadAaps(localStorage.getItem(CHOSEN_PROFILE_KEY) === ProfilEvaluateur.MEMBRE_ORGANISME);
      }
    });
  }

  loadAaps(isMembreOrganisme?: boolean): void {
    this.aapService.getAaps(isMembreOrganisme).subscribe((response: HttpResponse<Aap[]>) => {
      this.dataSource = new MatTableDataSource(response?.body);
      const isAapUpdatable = this.sharedFunction.isAapUpdatableByUser(this.user);
      const columnNotDisplayed = !this.displayedColumns.includes('select');

      this.enableAction =
        this.sharedFunction.isAapUpdatableByUser(this.user) ||
        (this.sharedFunction.isSiteAdmin() && this.sharedFunction.isUserInno(this.user));
      this.enableDesaffecterSuperviseur = false;

      if (isAapUpdatable && columnNotDisplayed) {
        this.displayedColumns = ['select', ...this.displayedColumns];
      }

      // Indique à la fonction de tri comment acceder au propriétés de l'objet utilisé pour le tri
      // ceci se fait parce que la propriété de l'objet element du html ne correspond pas au nom de la colonne
      this.dataSource.sortingDataAccessor = this.getSortingDataAccessor();
      if (this.sort) {
        this.sort.disableClear = true;
      }

      this.dataSource.sort = this.sort;

      this.favoriteAapStates = {};
      if (!this.user.favoriteAapIds) {
        this.user.favoriteAapIds = [];
      }
      this.activeToggleLabel = this.user.favoriteAapIds.length > 0 ? ToggleValues.MES_AAP : ToggleValues.TOUS_AAP;
      this.setFavoriteAapStates();
      this.combineFilters();
    });
  }

  setFavoriteAapStates(): void {
    this.dataSource.data.forEach(aap => {
      this.favoriteAapStates[aap.id] = {
        isFavorite: this.user.favoriteAapIds?.includes(aap.id),
      };
    });
  }

  private getSortingDataAccessor(): (item: Aap, property: string) => string {
    return (item, property) => {
      if (property === 'programme.type') {
        return item.programme?.type?.toLocaleLowerCase().trim();
      } else {
        return typeof (item as any)[property] === 'string' ? (item as any)[property]?.toLocaleLowerCase().trim() : (item as any)[property];
      }
    };
  }

  getProjets(id: string): void {
    this.router.navigate(['aaps/' + id + '/projets']);
  }

  createProg(): void {
    this.router.navigate(['creationAap']);
  }

  searchProjetsTransverseAaps(): void {
    this.router.navigate(['aaps-transverse/projets']);
  }

  updateAap(aap: Aap): void {
    this.router.navigate(['modifierAap/' + aap.id]);
  }

  addAapToFavorites(aapId: string): void {
    if (!this.user.favoriteAapIds) {
      this.user.favoriteAapIds = [];
    }
    this.user.favoriteAapIds.push(aapId);
    this.utilisateurService.patchUserAapFavorites(this.user.favoriteAapIds).subscribe({
      next: () => {
        this.combineFilters();
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  removeAapFromFavorites(aapId: string): void {
    if (!this.user.favoriteAapIds) {
      this.user.favoriteAapIds = [];
    }
    const indexToRemove: number = this.user.favoriteAapIds.indexOf(aapId, 0);
    if (indexToRemove > -1) {
      try {
        this.user.favoriteAapIds.splice(indexToRemove, 1);
      } catch (error) {
        this.showToastrService.error('Une erreur est apparue, veuillez réessayer');
        return;
      }
      this.utilisateurService.patchUserAapFavorites(this.user.favoriteAapIds).subscribe({
        next: () => {
          this.combineFilters();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    } else {
      this.showToastrService.error('Une erreur est apparue, veuillez réessayer');
    }
  }

  toggleFavorite(element: Aap) {
    const state = this.favoriteAapStates[element.id];
    if (!state.isFavorite) {
      this.addAapToFavorites(element.id);
      this.favoriteAapStates[element.id] = { isFavorite: true };
    } else {
      this.removeAapFromFavorites(element.id);
      this.favoriteAapStates[element.id] = { isFavorite: false };
      if (this.user.favoriteAapIds.length < 1) {
        this.activeToggleLabel = ToggleValues.TOUS_AAP;
        this.combineFilters();
      }
    }
  }

  /**
   * SELECTION FUNCTIONS
   */

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * SUPERVISEUR FUNCTIONS
   */

  /** Affectation/Désaffectation superviseur */
  affecterDesaffecterSuperviseur(title: string): void {
    if (!this.selection.selected?.length) {
      this.showToastrService.error('Merci de sélectionner au moins un AAP.');
      return;
    }

    const dialogRef = this.dialog.open(AffectationIntervenantModalComponent, {
      data: {
        title: title,
        subTitle:
          title === this.desaffecter
            ? 'La désaffectation concerne uniquement les AAPs sélectionnés. Dans le cas de la désaffectation, les intervenants ne seront pas notifiés'
            : null,
        textGoButton: 'Envoyer',
        textReturnButton: 'Annuler',
        aapList: this.selection.selected,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.aapsIdArray) {
        const url =
          title === this.affecter
            ? this.evaluateurService.affectSuperviseurs(result.aapsIdArray, result.emails)
            : this.evaluateurService.desaffectSuperviseurs(result.aapsIdArray, result.emails);
        url.subscribe({
          next: res => {
            const errorArray = res.body;
            if (errorArray.length && title !== this.desaffecter) {
              this.showAffectationError(errorArray);
            } else {
              this.showAffectationSuccess(title);
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
      }
    });
  }

  showAffectationSuccess(title: string): void {
    if (title === this.affecter) this.showToastrService.success("L'opération d'affectation a été appliquée avec succès.");
    else this.showToastrService.success("L'opération de désaffectation a été appliquée avec succès.");
  }

  showAffectationError(errorArray: { email: string; projects: any[]; message: string }[]): void {
    const incorrectList: string[] = [];
    errorArray.forEach(error => {
      error.projects?.forEach(project => {
        incorrectList.push(
          `L'email ${error.email} est affecté en tant que ${IntervenantRoles.toString(project.utilisateurRole)} sur le projet ${
            project.acronyme ? project.acronyme : project.identifiantPXL
          }`
        );
      });

      if (error.message) {
        incorrectList.push(error.message);
      }
    });

    this.activeToast = this.toastr.error(null, "Erreurs d'affectation.", {
      closeButton: true,
      toastComponent: CustomToastComponent,
      tapToDismiss: false,
      disableTimeOut: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: {
        incorrectList,
        type: CustomToastComponent.AFFECTATION_ERROR,
      },
    });
  }

  onUserUpdate(user: Utilisateur) {
    this.user = user;
    this.showTransverseSearch = PermissionUtils.hasPermission(user, EnumPermissionUtilisateur.PROJET_GLOBAL_SEARCH);
  }

  /**
   * Redirige vers l'espace candidats
   */
  redirectToCandidats(): void {
    window.location.href = environment.espaceCandidat + '/projets';
  }

  combineFilters($event?: ToggleValues): void {
    if (!$event) {
      $event = this.activeToggleLabel;
    }

    this.dataSource.filterPredicate = (data: Aap) => {
      return this.filterMyAapOrAllAap($event, data) && this.isAapOfSelectedType(data);
    };
    this.dataSource.filter = 'apply';
  }

  filterMyAapOrAllAap($event: ToggleValues, data: Aap): boolean {
    if (!this.user.favoriteAapIds) {
      this.user.favoriteAapIds = [];
    }
    this.activeToggleLabel = $event === ToggleValues.MES_AAP ? ToggleValues.MES_AAP : ToggleValues.TOUS_AAP;
    return $event === ToggleValues.MES_AAP ? this.user.favoriteAapIds.includes(data.id) : true;
  }

  isAapOfSelectedType(data: Aap): boolean {
    const aapFilters = this.aapTypeComponent.getSelectedValues();

    if (aapFilters.length === 0) {
      return true;
    }

    const conditions: { [key: string]: () => boolean } = {
      'AAPs brouillons': () => data.statut === EnumAapStatut.BROUILLON,
      'AAPs ouverts': () => data.statut === EnumAapStatut.OUVERT,
      'AAPs fermés': () => data.statut === EnumAapStatut.FERME,
      'AAPs Immersion': () => data.immersion,
    };

    return aapFilters.some(filterData => conditions[filterData.value]?.());
  }

  resetAllFilters(): void {
    this.aapTypeComponent.reset();
  }

  submitSearch(): void {
    this.combineFilters(this.activeToggleLabel);
  }

  onClickGoToChoice(): void {
    localStorage.removeItem(CHOSEN_PROFILE_KEY);
    this.router.navigate(['evaluateur-choice']);
  }
}

export enum ToggleValues {
  TOUS_AAP = 'Tous les AAPs',
  MES_AAP = 'Mes AAPs',
}

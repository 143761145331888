<pxl-sidenav-projets *ngIf="aap.id" [projets]="projets" [aap]="aap"></pxl-sidenav-projets>

<div class="page-content page-content--no-scroll list-page">
  <div class="list-page__header">
    <h1 class="list-page__title" *ngIf="!aap.code">Les projets de l’AAP {{ aap.codeComptable }}</h1>
    <h1 class="list-page__title" *ngIf="aap.code">Les projets de l’AAP {{ aap.code }}</h1>

    <div [libMyRemoveIfFeatureOff]="'auto-affectation'">
      <lib-pxl-toggle
        *ngIf="sharedFunction.isAutoAffectableOnAap(aap, user)"
        [labels]="toggleLabels"
        [defaultLabel]="defaultToggleLabel"
        (changeToggleEvent)="onChangeToggle($event)"
      ></lib-pxl-toggle>
    </div>

    <div class="list-page__dropdowns">
      <button
        id="switch-to-evaluateur"
        class="ds-btn ds-btn--secondary"
        *ngIf="sharedFunction.isSiteEval()"
        (click)="redirectToCandidats()"
      >
        Accéder à mon espace candidat
      </button>
      <button
        type="button"
        *ngIf="canUpdateWorkflow()"
        class="list-page__create-button ds-btn ds-btn--secondary"
        [matMenuTriggerFor]="Workflow"
        #menuTrigger="matMenuTrigger"
        (click)="showMsg()"
      >
        Workflow
        <lib-pxl-icon class="icon" icon="icon-arrow-expand-more"></lib-pxl-icon>
      </button>
      <mat-menu #Workflow="matMenu" class="menu-workflow" xPosition="before">
        <!-- Workflow options -->
        <div *ngFor="let wkf of typeWkf">
          @if (wkf.accessible) {
          <label class="ds-input-group__label menu-label">{{ EnumProjetEtape.toString(wkf.etape) }}</label>
          <div *ngFor="let statut of wkf.statuts">
            @if (statut.accessible) {
            <div class="menu-etape" mat-menu-item>
              <label style="text-decoration: underline">{{ statut.label }}</label>
              <div>
                <ng-container *ngFor="let option of statut.options">
                  <button
                    type="button"
                    class="card__action-button"
                    (click)="updateProjetEtapes(wkf.etape, option.statut)"
                    [disabled]="disableMenu(wkf.etape, option.type)"
                  >
                    <lib-pxl-icon class="icon" icon="icon-action-play" *ngIf="option.type === TYPE_EN_COURS"></lib-pxl-icon>
                    <lib-pxl-icon class="icon" icon="icon-human-thumb-up-alt" *ngIf="option.type === TYPE_VALIDE"></lib-pxl-icon>
                    <lib-pxl-icon class="icon" icon="icon-human-thumb-down-alt" *ngIf="option.type === TYPE_REJETE"></lib-pxl-icon>
                    <lib-pxl-icon class="icon" icon="icon-action-stop" *ngIf="option.type === TYPE_TERMINE"></lib-pxl-icon>
                  </button>
                </ng-container>
              </div>
            </div>
            }
            <hr style="width: 85%; background: #e9e9e9; margin-left: 16px" />
          </div>
          }
        </div>
        <!-- Fin workflow options -->
      </mat-menu>

      <!-- TODO Remove feature fliping archive V1 -->
      <button
        type="button"
        id="actions-btn"
        class="ds-btn ds-btn--primary"
        placement="bottom-right"
        [ngbPopover]="popContent"
        *ngIf="sharedFunction.isEvaluateurInstructeurExterne(evaluateur)"
        [libMyRemoveIfFeatureOff]="'archiveV1'"
      >
        Actions
        <lib-pxl-icon class="icon" icon="icon-arrow-expand-more" aria-hidden="true"></lib-pxl-icon>
      </button>
      <!-- TODO Remove feature fliping archive V1 -->

      <div (libClickOutside)="popover.close()" id="actions-dropdown-menu">
        <button
          id="actions-btn"
          class="ds-btn ds-btn--primary"
          placement="bottom-right"
          [ngbPopover]="popContent"
          [libMyRemoveIfFeatureOff]="'archiveV2'"
          triggers="manual"
          (click)="popover.isOpen() ? popover.close() : popover.open()"
          #popover="ngbPopover"
          [autoClose]="false"
        >
          Actions
          <lib-pxl-icon class="icon" icon="icon-arrow-expand-more" aria-hidden="true"></lib-pxl-icon>
        </button>
        <ng-template #popContent>
          <div [libMyRemoveIfFeatureOff]="'auto-affectation'">
            <div *ngIf="!isMesProjetsToggleSelected && canUserAutoAffect">
              <div class="popover_link border-top-0">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="openAutoAffectation(false); popover.close()">M'affecter</a>
              </div>
            </div>
            <div *ngIf="isMesProjetsToggleSelected && canUserAutoAffect">
              <div class="popover_link border-top-0">
                <lib-pxl-icon class="icon" icon="icon-action-minus-circle-outline"></lib-pxl-icon>
                <a (click)="openAutoDeaffectation(); popover.close()">Me désaffecter</a>
              </div>
            </div>
          </div>

          <div class="popover_container" *ngIf="sharedFunction.canInstructeurAffectEvaluateur(aap, user)">
            <button class="popover_title" (click)="juryRegionalDiv.classList.toggle('d-none')">
              {{ aap.ilab ? 'Jury Régional' : 'Evaluateur' }}
              <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
            </button>
            <div #juryRegionalDiv class="d-none">
              <div class="popover_link" *ngIf="!aap.ilab">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="affecterDesaffecterIntervenant(affecter); popover.close()">Affecter</a>
              </div>
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="affecterIntervenantLecttureSeule(); popover.close()">Affecter en lecture seule</a>
              </div>
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-minus-circle-outline"></lib-pxl-icon>
                <a (click)="affecterDesaffecterIntervenant(desaffecter); popover.close()">Désaffecter</a>
              </div>
            </div>
          </div>

          <div class="popover_container" *ngIf="canSeeProjetToggleAction()">
            <button class="popover_title" (click)="projetDiv.classList.toggle('d-none')">
              Projet <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
            </button>
            <div #projetDiv class="d-none">
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="addEvaluationDate(); popover.close()">Date d'évaluation</a>
              </div>
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="addAuditionDate(); popover.close()">Date d'audition</a>
              </div>
            </div>
          </div>

          <div class="popover_container" *ngIf="sharedFunction.canUserExtractAapProjets(aap, user)">
            <button class="popover_title" (click)="extractionsDiv.classList.toggle('d-none')">
              Extractions <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
            </button>
            <div #extractionsDiv class="d-none">

              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                <a (click)="downloadPartenaire(); popover.close()">Partenaires</a>
              </div>
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                <a (click)="downloadExportProjets(); popover.close()">Projets</a>
              </div>
            </div>
          </div>

          <div class="popover_container" [libMyRemoveIfFeatureOff]="'archiveV2'" *ngIf="
              (aap.ficheComPresent && sharedFunction.canUserReadAtLeastOneProject(user)) ||
              sharedFunction.canInstructeurDownoaldArchive(user)
            ">
            <button class="popover_title" (click)="archiveDiv.classList.toggle('d-none')">
              Archive <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
            </button>
            <div #archiveDiv class="d-none">
              <div class="popover_link" *ngIf="sharedFunction.canInstructeurDownoaldArchive(user)">
                <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                <a (click)="downloadDossiersComplets(); popover.close()">Télécharger dossiers complets</a>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="page-content--no-scroll filter_table_content">
    <!-- Component de recherche -->
    <project-search
      *ngIf="showSearchBar && sharedFunction.canEvaluateurFilterProjects(aap, user)"
      [thematiqueDataList]="thematiqueDataList"
      [releveDataList]="releveDataList"
      [etapeDataList]="etapeDataList"
      [statutDataList]="statutDataList"
      [tacheDataList]="tacheDataList"
      [drdlDataList]="drdlDataList"
      [comiteDataList]="comiteDataList"
      [phaseDecisionDataList]="phaseDecisionDataList"
      [aapId]="aap.id"
      [affectedOnly]="isMesProjetsToggleSelected"
      (searchEventEmitter)="searchEventCalled($event)"
    ></project-search>

    <!-- TODO Remove feature fliping archive V1 -->
    <pxl-tableau-projet
      [libMyRemoveIfFeatureOff]="'archiveV1'"
      [(selection)]="selection"
      [displayedColumns]="displayedColumnsOld"
      [(data)]="projetTableData"
      (elementClicked)="goToProjetInfo($event)"
      (sortChange)="onSortChange($event)"
    ></pxl-tableau-projet>
    <!-- TODO Remove feature fliping archive V1 -->

    <!-- Component de tableau de projets -->
    <pxl-tableau-projet
      [libMyRemoveIfFeatureOff]="'archiveV2'"
      [(selection)]="selection"
      [displayedColumns]="displayedColumns"
      [(data)]="projetTableData"
      (elementClicked)="goToProjetInfo($event)"
      [savedSort]="savedSort"
      (sortChange)="onSortChange($event)"
    ></pxl-tableau-projet>
  </div>
</div>